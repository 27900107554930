import styled, { keyframes } from 'styled-components';

const GradientAnimation = keyframes`
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

export const Gradient = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  color: #fff;
  background: ${({ colors, degrees }) => {
    return `linear-gradient(-${degrees}deg, ${colors[0]}, ${colors[1]}, ${colors[2]}, ${colors[3]})`;
  }};
  background-size: 400% 400%;
  -webkit-animation: ${GradientAnimation} 15s ease infinite;
  -moz-animation: ${GradientAnimation} 15s ease infinite;
  animation: ${GradientAnimation} 15s ease infinite;
`;
