import styled from 'styled-components';

export const DetailsProfileContent = styled.div``;

export const ListCapacities = styled.ul`
  list-style: none;
  margin-bottom: 32px;
`;

export const ItemFeaturedList = styled.li`
  font-weight: bold
`;

export const ItemDateTime = styled.li`
  color: ${({ theme }) => theme.secondColor}
`;

export const InfoBlock = styled.div`
  padding-top: 32px;
  padding-bottom: 48px;
  border-top: solid 1px ${({ theme }) => theme.transparentLine}
`;