import { content as portugueseProfile } from './content/profile/pt.js';
import { content as englishProfile } from './content/profile/en.js';

import { projects as portugueseProjects } from './content/projects/pt.js';
import { projects as englishProjects } from './content/projects/en.js';

const portuguese = {
    ...portugueseProfile,
    projects: portugueseProjects,
};

const english = {
    ...englishProfile,
    projects: englishProjects,
};

export const en = english;
export const pt = portuguese;