import React, { useEffect } from 'react';
import { content } from '../../i18n/Model';
import { projects } from '../../i18n/content/projects/en';

import ProjectDescription from '../../components/projectDescription/ProjectDescription';
import ProjectLinks from '../../components/projectLinks/ProjectLinks';

import { Content } from '../../styles/common';
import { BackButton, ImageCover, ImageProject } from './Project.styles';

const getProjectUrl = (location) => {
  const url = location.pathname;
  const rgx = /[^/]*$/i;
  return url.match(rgx) && url.match(rgx)[0];
};

const getProjectByUrl = (url) =>
  projects && projects.list && projects.list.findIndex((item) => item.url === url);

const Project = ({ location, t, history, setLogoColor }) => {
  const urlProject = getProjectUrl(location);
  const projectIndex = getProjectByUrl(urlProject);
  const text = content.projects.list[projectIndex].text;

  const project = projects.list[projectIndex];
  const year = new Date(project.date).getFullYear();

  useEffect(() => {
    setLogoColor(project.color);
  }, []);

  return (
    <Content>
      <BackButton onClick={() => history.goBack()}>
        {t(`actions.back`)}
      </BackButton>

      {project.imageCover
        ? <ImageCover image={project.imageCover}></ImageCover> : ''}

      <h2> {t(`projects.list.${projectIndex}.title`)} </h2>
      <ProjectDescription
        description={text}
        index={projectIndex}
        year={year}
        t={t}
        project={project}
      />

      <ProjectLinks project={project} t={t}></ProjectLinks>

      {project && project.images && project.images.length
        ? (project.images.map((image, index) => (
          <ImageProject src={`../images/projects/${image}`} key={index} />
        )))
        : ''
      }
    </Content>
  )
};

export default Project;