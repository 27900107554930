export const content = {
  basic_info: {
    name: 'Maria Luísa',
    site: 'malu.dev',
    full_name: 'Maria Luísa Costa Pinto',
    resume: [
      'Master’s student',
      'Graphic Designer',
      'Full stack Developer',
      'UI Designer',
      'Data Visualization Designer',
    ],
    description: 'A black woman in technology',
    office: 'Consultant Developer at ThoughtWorks',
    hello: "Hi, I'm Maria Luísa",
    text: '',
  },
  intro: {
    title: 'Maria what?',
    name: 'Maria Luísa Costa Pinto',
    text: 'I\'m Maria Luísa Costa Pinto. I currently work as a Senior Software Development Consultant at <a href="https://www.thoughtworks.com/pt" alt="link to ThoughtWorks website" target="_blank">ThoughtWorks BR</a>. I am a student of the Master\'s degree in Computer Science by the Federal University of Minas Gerais, graduated in Systems of Information at the Federal University of Minas Gerais (2016) and Computer Technician at Escola Politécnica de Minas Gerais (2010). I have been working with technology for a few years. I define myself as a front-end developement and UI design.',
  },
  profile: {
    title: 'Profile',
    info: [
      {
        subtitle: 'Research',
        list: [
          'UI - UX Design',
          'Data visualization',
          'Data mining for web',
          'Information Retrieval',
        ],
      },
      {
        subtitle: 'Development',
        list: [
          'Js, React, React Native, Jquery, AngularJs, Angular, Ionic Framework, D3js, WinJs, NodeJs',
          'HTML5, CSS3, LESS, SASS, Bootstrap, Angular Material, XML',
          'MySQL, MongoDB',
          'PHP, C, Java, Shell Script',
          'Wordpress, Joomla, Drupal',
          'Python, Jupyter Notebook',
        ],
      },
      {
        subtitle: 'Design',
        list: [
          'Adobe Photoshop',
          'Adobe Indesign',
          'Adobe Illustrator',
          'Adobe XD',
          'Material Design',
          'Sony Vegas (Video editor)',
        ],
      },
    ],
  },
  academic: {
    title: 'Academic Education',
    list: [
      {
        date: 'Mar 2018 - Present',
        place:
          'Department of Computer Science - Federal University of Minas Gerais, DCC - UFMG',
        course: 'Master in Computer Science',
      },
      {
        date: 'Mar 2012 - 2016',
        place:
          'Department of Computer Science - Federal University of Minas Gerais, DCC - UFMG',
        course: 'Bachelor of Information Systems',
      },
      {
        date: 'Aug 2009 - Dec 2010',
        place: 'Escola Politécnica de Minas Gerais, Colégio Polimig',
        course: 'Computer Technician',
      },
    ],
  },
  events: {
    title: 'Workshops, complementary courses and events',
    list: [
      {
        date: 'Jan 2017 - Jul 2018',
        place: 'UPTIME - Communication in English',
        course: 'English Course - College',
        description: 'Fluency in 12 months',
      },
      {
        date: 'July 2017',
        place: 'Avalanche Missões Urbanas - Vitória, ES',
        course: 'Communication and Arts',
        description:
          'Creativity, Art and Religion, Writing, Communication, Briefing and Brainstorming, Screenwriting, Elementary Design, Social Media, Visual Perception, Photography, Short Films, Urban Intervention, Photography History, Instagram and social relevance, Lightpainting, Theater Workshop, Body Expression, Dance History, Urban Dances, History and Politics ',
      },
      {
        date: 'Mar 2013 - Jul 2014',
        place: 'CNPQ Scientific Initiation Scholarship',
        course:
          'Scientific Initiation Program and Master in Mathematics, PICME',
      },
      {
        date: 'Jul 2008 - Dec 2009',
        place: 'CNPQ Scientific Initiation Scholarship Jr.',
        description: 'Brazilian Mathematics Olympiad of Public Schools, OBMEP',
        course: 'OBMEP Scientific Initiation Program',
      },
      {
        date: 'Dec 2016',
        course: 'Content Marketing',
        place: 'Ministers: Rock Content',
        description: 'Credit Hours: 10hrs',
      },
      {
        date: 'Aug 2013 - Dec 2014',
        course: 'English Course - Basic Level',
        place: 'Cacs - Foreign Language Teaching Project',
        description: '',
      },
      {
        date: 'jan 2014',
        course: 'CoW - HTML5 | CSS3 | Javascript',
        place: 'Ministers: Bernard de luna and Zeno Rocha',
        description: 'Credit Hours: 12hrs',
      },
      {
        date: 'Dec 2013',
        course: 'MEAN Stack - MongoDB, ExpressJS, AngularJS, NodeJS',
        place: 'Minister: Switzerland',
        description: 'Hours: 20hrs',
      },
      {
        date: 'Nov 2013',
        course: 'DRUPAL training',
        place: 'Minister: Fisqua',
        description: 'Credit Hours: 120hrs',
      },
      {
        date: 'Oct 2013 - Nov 2013',
        course: 'Advanced PHP',
        place: 'Minister: Igor Rafael',
        description: 'Credit Hours: 36 hrs',
      },
      {
        date: 'Aug 2013',
        course: 'Front in BH',
        place: '',
        description: '',
      },
      {
        date: 'Feb 2013',
        course: 'Android Bootcamp',
        place: 'Minister: Leonardo Barros (GDG - Belo Horizonte)',
        description: '',
      },
    ],
  },
  awards: {
    title: 'Awards and curiosities',
    list: [
      {
        date: 'Mar 2019',
        competition: 'Interview for UFMG"s communication channels',
        award:
          'Video about my professional and academic career, available on all official channels of the University.',
        description:
          'Link on youtube: <a href="https://www.youtube.com/watch?v=HLuY2-Z4EXE&t=1s" target="_blank"> click here to watch </a>.',
      },
      {
        date: 'Feb 2019',
        competition: 'The Big Hackathon - Campus Party Brasil 12',
        award:
          '3rd place in the Final and in the first phase 1st place in the Human Rights and Finance categories',
        description:
          'I participated in a Hackathon at Campus Party BR 12 in São Paulo where I was honored to get an award.',
      },
      {
        date: 'Dec 2018',
        competition: 'Lecture on Data Visualization',
        award: 'Speaker',
        description:
          'Contributes to the Minutrends Day event with a lecture on Data Visualization.',
      },
      {
        date: 'Jul 2018',
        competition:
          'Competition to create a commemorative stamp - 50 years ICEx UFMG',
        award: 'Developed the competition"s winning logo',
        description:
          'Participation in a logo contest, where I had the honor of being the winner.',
      },
      {
        date: 'Dec 2013',
        competition: 'Marketing and Sales Director',
        award: 'Award for good management',
        description:
          'Recognition by UFMG Informática Jr for good management in the Marketing department.',
      },
      {
        date: 'Oct 2010',
        competition: 'Technological fair',
        award: '4th place at POLIVITAL TECHNOLOGY AND SCIENCES FAIR',
        description:
          'Development of a game in VB6, interactive with questions and answers previously inserted in an external database.',
      },
      {
        date: 'OBMEP 2009',
        competition:
          '5th Brazilian Mathematics Olympiad of Public Schools, OBMEP',
        award: 'Honorable Mention',
        description: '',
      },
      {
        date: 'OBMEP 2008',
        competition:
          '4th Brazilian Mathematics Olympiad of Public Schools, OBMEP',
        award: 'Honorable Mention',
        description: '',
      },
      {
        date: 'OBMEP 2007',
        competition:
          '3rd Brazilian Mathematics Olympiad of Public Schools, OBMEP',
        award: 'Honorable Mention',
        description: '',
      },
    ],
  },
  professional: {
    title: 'Professional Experience',
    list: [
      {
        date: 'Jan 2020 - Present',
        place: 'ThoughtWorks',
        office: 'Consultant Developer',
        knowledge: 'React, React Native',
      },
      {
        date: 'Jul 2018 - Dec 2019',
        place: 'Minutrade',
        office: 'UI Developer',
        knowledge:
          'UI Design, Javascript, React, AngularJs, Node, MongoDB, Adobe Xd, Adobe Photoshop',
      },
      {
        date: 'Jul 2015 - Jun 2018',
        place: 'Codificar Sistemas Tecnológicos',
        office: 'UI / UX designer, developer',
        knowledge:
          'Javascript, React Native, Angular Js, Angular 4, Node Js, Gulp, LESS | SASS, Ionic Framework, UI Design for Andoid and iOS App, UI Design for Web Systems, Angular Material and Material Design, Responsive Layout , XML Front-end for Android and Objective C for iOS. ',
      },
      {
        date: 'Jun 2015 - Jul 2015',
        place: 'Paiva Piovesan Softwares',
        office: 'Front-end Developer',
        knowledge: 'AngularJs - Kendo UI Framework - c #',
      },
      {
        date: 'Jan 2014 - Present',
        place: 'Freelancer',
        office: 'Web Designer, Graphic Designer, web programming',
        knowledge: '',
      },
      {
        date: 'Aug 2013 - Jun 2015',
        place: 'UFMG Communication Center',
        office: 'Web Developer, Trainee',
        knowledge:
          'JavaScript, Node Js - MongoDB - Angular Js, PHP, Wordpress, Drupal CMS, HTML5 | CSS3, LESS | SASS, Grunt | Gulp',
      },
      {
        date: 'Mar 2012 - Dec 2013',
        place: 'UFMG Informática Jr.',
        office:
          'Director of Marketing and Sales, People Management, Scrum Developer',
        knowledge:
          'Preparation of negotiation proposals, Survey of software requirements. Development of Marketing campaigns. People and project management. Technical experience in: HTML, CSS, JavaScript, Bootstrap, PHP, Mysql, Joomla, Java for Android, Scrum, Git. ',
      },
      {
        date: 'Aug 2011 - Nov 2011',
        place: 'Sabará Municipality in partnership with SINE-Sabará',
        office: 'Computer Technician',
        knowledge: 'Computer maintenance. Support and staff training.',
      },
    ],
  },
  menu_header: {
    about: 'About me',
    portfolio: 'Portfolio',
  },
  buttons: {
    portfolio: 'Portfolio',
    profile: 'Profile',
    dark_theme: 'Dark',
    light_theme: 'Light',
  },
  contact: {
    title: 'Contact',
  },
  footer: {
    place: 'Belo Horizonte, Brazil',
    contact: 'Contact me',
    cv: 'Download CV',
    social_networks: 'Social networks',
    btn_download_pt: 'Português',
    btn_download_en: 'English',
    sentence: '"I know that I know nothing" - Socrates',
    directs: '© 2020 Maria Luísa Costa Pinto. All Rights Reserved.',
  },
  actions: {
    back: 'Go back',
  },
  messages: {
    job_type_free: 'I developed this project as a ',
    job_type_company: 'I developed this project working at ',
    access_site: 'Click here to access website project',
    access_behance: 'Clicke here to see project on Behance',
    access_code: 'Click to see project code',
  },
  alt: {
    project_link_site: 'Project website link',
    project_link_code: 'Project source code link',
  },
};
