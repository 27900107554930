import React from 'react';
import { projects } from '../../i18n/content/projects/en';

import {
  Tags, Tag, HashTag
} from './TagsProject.styles';

const buildHash = (tag, objectTags) => {
  if (!objectTags[tag])
    objectTags[tag] = 1
  else
    objectTags[tag]++
};

const getTags = (arrayTags) => {
  const tags = { all: 0 };
  arrayTags.map(tag => buildHash(tag, tags));
  tags.all++;
  return tags;
};

const getTagsOfAllProjects = (projects) => {
  let allTags = {};
  projects && projects.map((project) => {
    const projectTags = project.tags && project.tags.length &&
      getTags(project.tags);
    allTags = { ...projectTags, ...allTags };
  });
  return allTags;
};

const TagsProject = ({ t, clickTag }) => {
  const projectsList = projects.list;
  const tags = getTagsOfAllProjects(projectsList);

  return (
    <Tags>
      {Object.entries(tags).map((tag, index) =>
        <Tag key={index} onClick={() => clickTag(tag[0])}>
          <HashTag>#</HashTag>
          {tag[0]}
        </Tag>
      )}
    </Tags>
  )
};

export default TagsProject;
