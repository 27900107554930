export const model = {
  'basic_info': {
    'name': '',
    'site': '',
    'full_name': '',
    'resume': [
      '',
      '',
      '',
      '',
      ''
    ],
    'description': ''
  },
  'intro': {
    'title': '',
    'name': '',
    'text': ''
  },
  'profile': {
    'title': '',
    'info': [
      {
        'subtitle': '',
        'list': [
          '',
          '',
          '',
          '',
        ],
      },
      {
        'subtitle': '',
        'list': [
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      {
        'subtitle': '',
        'list': [
          '',
          '',
          '',
          '',
          '',
          '',
        ]
      }
    ]
  },
  'academic': {
    'title': '',
    'list': [
      {
        'date': '',
        'place': '',
        'course': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
      }
    ],
  },
  'events': {
    'title': '',
    'list': [
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      },
      {
        'date': '',
        'place': '',
        'course': '',
        'description': '',
      }
    ],
  },
  'awards': {
    'title': '',
    'list': [
      {
        'date': '',
        'competition': '',
        'award': '',
        'description': '',
      },
      {
        'date': '',
        'competition': '',
        'award': '',
        'description': '',
      },
      {
        'date': '',
        'competition': '',
        'award': '',
        'description': '',
      },
      {
        'date': '',
        'competition': '',
        'award': '',
        'description': '',
      },
      {
        'date': '',
        'competition': '',
        'award': '',
        'description': '',
      },
      {
        'date': '',
        'competition': '',
        'award': '',
        'description': '',
      },
      {
        'date': '',
        'competition': '',
        'award': '',
        'description': '',
      },
      {
        'date': '',
        'competition': '',
        'award': '',
        'description': '',
      },
      {
        'date': '',
        'competition': '',
        'award': '',
        'description': '',
      }
    ]
  },
  'professional': {
    'title': '',
    'list': [
      {
        'date': '',
        'place': '',
        'office': '',
        'knowledge': ''
      },
      {
        'date': '',
        'place': '',
        'office': '',
        'knowledge': ''
      },
      {
        'date': '',
        'place': '',
        'office': '',
        'knowledge': '',
      },
      {
        'date': '',
        'place': '',
        'office': '',
        'knowledge': ''
      },
      {
        'date': '',
        'place': '',
        'office': '',
        'knowledge': ''
      },
      {
        'date': '',
        'place': '',
        'office': '',
        'knowledge': ''
      },
      {
        'date': '',
        'place': '',
        'office': '',
        'knowledge': ''
      },
      {
        'date': '',
        'place': '',
        'office': '',
        'knowledge': ''
      }
    ]
  },
  'menu_header': {
    'about': '',
    'portfolio': ''
  },
  'buttons': {
    'portfolio': '',
    'curriculum': ''
  },
  'contact': {
    'title': ''
  },
  'footer': {
    'place': '',
    'column1_title': '',
    'column2_title': '',
    'column3_title': '',
    'btn_download_pt': '',
    'btn_download_en': ''
  }
};