export const SOCIAL_NETWORKS = [
  {
    fid: '04',
    status: true,
    name: 'Linkedin',
    link: 'https://www.linkedin.com/in/maria-luísa-costa-pinto-5ab894aa',
  },
  {
    fid: '06',
    status: true,
    name: 'Github',
    link: 'https://github.com/marialuisacp',
  },
  {
    fid: '07',
    status: true,
    name: 'Bitbucket',
    link: 'https://bitbucket.org/marialuisa/',
  },
  {
    fid: '08',
    status: true,
    name: 'Behance',
    link: 'https://www.behance.net/marialuisacp',
  },
  {
    fid: '11',
    status: true,
    name: 'Observable',
    link: 'https://observablehq.com/@marialuisacp',
  },
  {
    fid: '12',
    status: true,
    name: 'Medium',
    link: 'https://medium.com/@marialuisacp',
  },
];
