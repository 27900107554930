import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import * as serviceWorker from './serviceWorker';

import { en, pt } from "./i18n/Languages.js";

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  fallbackLng: ['en', 'pt'],
  resources: {
    en: {
      common: en
    },
    pt: {
      common: pt
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();