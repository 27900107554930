import React, { Fragment, useEffect, useState } from 'react';
import { content } from '../../i18n/Model';
import { projects as projectsContent } from '../../i18n/content/projects/en';
import TagsProject from '../../components/tagsProject/TagsProject';
import ProjectsList from '../../components/projectsList/ProjectsList';
import {
  ContentProjects, TitleProject, DescriptionProject,
} from './Projects.styles';

const Projects = ({ t }) => {
  const projectsList = projectsContent && projectsContent.list;
  const [currentTag, setCurrentTag] = useState('all');
  const [projects, setProjects] = useState(projectsList);

  const filterProjectsByTag = (tag) => {
    return tag === 'all'
      ? projectsList
      : projectsList.filter((project) =>
        project.tags && project.tags.length && project.tags.find(tagProject => tagProject === tag));
  };

  const handleClickTag = (tag) => {
    setCurrentTag(tag);
  };

  useEffect(() => {
    const currentProjects = filterProjectsByTag(currentTag);
    setProjects(currentProjects);
  }, [currentTag]);

  return (
    <Fragment>
      <ContentProjects>
        <div>
          <h1>{t('projects.title')}</h1>
          <p>
            {t('projects.description')}
          </p>
        </div>

        <TagsProject clickTag={handleClickTag}></TagsProject>
        <center>
          <ProjectsList projects={projects} t={t}></ProjectsList>
        </center>
      </ContentProjects>
    </Fragment>
  )
};

export default Projects;
