import styled from 'styled-components';

export const Tags = styled.div`
  display: block;
  margin-bottom: 24px;
  width: 96%;
`;

export const Tag = styled.span`
  padding: 8px;
  margin: 18px 8px;
  cursor: pointer;
  border: solid 1px ${({ theme }) => theme.primaryColor};
  display: inline-block;
`;

export const HashTag = styled.span`
  color: ${({ theme }) => theme.transparentLine} !important;
`;