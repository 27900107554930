import React, { Fragment, useState, useEffect } from 'react';
import { colorsPallete } from '../../config/theme';

import Intro from '../../components/intro/Intro';
import DetailsProfile from '../../components/detailsProfile/DetailsProfile';
import { Content } from '../../styles/common';
import { PageArea } from './Profile.styles';

const Profile = ({ t, setLogoColor }) => {
  const [showProfile, setShotProfile] = useState(true);

  const haveToshowProfile = (value) => {
    setShotProfile(value);
  };

  useEffect(() => {
    setLogoColor(colorsPallete[0]);
  }, [setLogoColor]);

  return (
    <>
      <PageArea>
        <Fragment>
          <Intro showProfile={haveToshowProfile} t={t}></Intro>
        </Fragment>
      </PageArea>
    </>
  );
};

export default Profile;
