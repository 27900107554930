export const projects = {
  'title': 'My projects',
  'description': 'Hi, here you can know a little more about my work. Some of the projects I worked on cannot be displayed in this portfolio due to privacy and confidentiality of information. However I have separated a list of some jobs, either as a freelancer or as a member of some company that summarize the work that I develop. You can filter by project categories:',
  'list': [
    {
      'title': 'Notes app - UI/UX Study project',
      'name': 'notes-app-ui',
      'url': 'notes-app-ui',
      'color': '#ff693b',
      'description': 'A simple project of notes app',
      'text': [
        'This app is a simple mobile application of notes. The users can write short notes, like phone numbers, links, routine tasks and other things. I drew some illustration to the app to give a creative and cool mood. The colors was thought as post its. Here you can see more details about projection conception and creation. I did this project like a study project in UI/UX design, but I\'m developing this app to publish in app stores.. Below, you can see the project technologies used:',
        'Design and development',
        '<ul> <li>Adobe XD</li> <li>Adobe Illustration</li> <li>Photoshop</li><li>Icons: react-native-fontawesome</li><li>Images: drew by me</li><li>React Native</li> <li>React Navigation</li> <li>React Hooks</li> <li>Components: custom React Native components</li></ul>'
      ],
      'date': '2021-04-04',
      'tags': [
        'programming',
        'app',
        'ui design',
        'design',
      ],
      'imageCover': 'project_59.png',
      'fid': 59,
      'imageGallery': 'g_project_59.png',
      'images': [
        '59_1.jpg',
        '59_2.jpg',
        '59_3.jpg',
        '59_4.jpg',
        '59_5.jpg',
        '59_6.jpg',
        '59_7.jpg',
        '59_8.jpg',
        '59_9.jpg',
        '59_10.jpg',
      ],
      'company': 'Student',
      'links': {
        'site': [],
        'bhc': 'https://www.behance.net/gallery/116834655/Notes-App',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/react-native-ui-animations'
      }
    },
    {
      'title': 'Law office Lislene Costa - App',
      'name': 'lislene-costa-advocacia',
      'url': 'law-office-lislene-costa',
      'color': '#8c0403',
      'description': 'A web application and Android application for a law firm',
      'text': [
        'In this project I developed a task management service for a law firm of Lawyer Lislene Costa.',
        'A web application was created that manages the services performed by the office and an Android mobile application that receives notifications of expiration alerts. In addition, I also developed the office logo, because I needed it. ',
        'In summary, this was a complete product when it comes to technology and digital insertion: online service, graphic creation and applications for mobile devices',
        'The technologies used in the development were: <ul> <li> React </li> <li> React Native </li> <li> API for login with Google account </li> <li> Notification trigger service by Firebase </li> <li> Firebase Hosting </li> <li> Node.js APIs </li> <li> Using the Google Sheets API to consume some spreadsheet data </li> <li> MongoDB </ li> <li> Material Design </li> </ul> '
      ],
      'date': '2020-02-20',
      'tags': [
        'system',
        'programming',
        'app',
        'ui design',
        'design',
        'logo'
      ],
      'imageCover': 'project_58.png',
      'fid': 58,
      'imageGallery': 'g_project_58.png',
      'images': [
        '58_1.jpg',
        '58_2.jpg',
        '58_3.jpg',
        '58_4.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Biblexplorer',
      'name': 'biblexplorer',
      'url': 'biblexplorer',
      'color': '#008eba',
      'description': 'Exploration of Bible data',
      'text': [
        'Biblexplorer is a technological research and development project. It has been my main research tool focused on the areas of computing: Information Visualization, Information Retrieval, Natural Language Processing and UX Design. ',
        'This work was born at the university as a project for visualizing Bible information and as the project was being developed, new features were being added.',
        'In the Data Visualization scenario, the work contemplates the development of techniques to visualize texts and information about the Bible through graphics, maps and others. All features in visualization were made using the D3Js library. ',
        'In Natural Language Processing, research takes on challenges using state-of-the-art methods such as the use of Word Embeddings for the ranking of an SRI Information Retrieval System. The main idea in these areas is to develop a search engine that understands biblical concepts and contexts. ',
        'The work consists of a set of applications. The technologies used to develop this work were: AngularJs, D3Js, MongoDB, Spring Framework, PHP, Ionic Framework, Apache Lucene, DeepLearning4j, Shell Script. '
      ],
      'date': '2017-05-07',
      'tags': [
        'system',
        'programming',
        'site',
        'dataviz',
        'ui design',
        'design'
      ],
      'imageCover': 'project_01.png',
      'fid': 1,
      'imageGallery': 'g_project_01.png',
      'images': [
        '1_11.png',
        '1_12.png',
        '1_1.png',
        '1_2.png',
        '1_3.png',
        '1_4.png',
        '1_5.png',
        '1_6.png',
        '1_7.png',
        '1_10.png',
        '1_8.png',
        '1_9.png',
        '1_13.png',
        '1_14.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://v1.biblexplorer.com.br/'
        ],
        'bhc': '',
        'app_and': 'https://play.google.com/store/apps/details?id=com.br.biblexplorer',
        'app_ios': 'https://itunes.apple.com/us/app/biblexplorer/id1144180913',
        'comp': ''
      }
    },
    {
      'title': 'United Statisticians',
      'name': 'united-statisticians',
      'url': 'united-statisticians',
      'color': '#f8a945',
      'description': 'UI System Design',
      'text': [
        'I developed the prototype of the United Statisticians web portal interfaces. It was a work of UI Design of all the screens in the system. ',
        'United Statisticians is an online platform for statistical consultants. For students, researchers and companies looking for statistical support (data analysis, college tests, market research, etc.), United Statisticians offers an online platform that helps clients find statistical consultants, statistical consulting companies and data scientists in a few clicks. I also designed some banners, personals and promotional material. '
      ],
      'date': '2017-05-07',
      'tags': [
        'design',
        'system',
        'programming',
        'illustration',
        'dataviz',
        'ui design'
      ],
      'imageCover': 'project_14.png',
      'imageGallery': 'g_project_14.png',
      'fid': 14,
      'images': [
        '14_1.png',
        '14_3.png',
        '14_2.png',
        '14_4.png',
        '14_5.png',
        '14_6.png',
        '14_7.png',
        '14_8.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'https://unitedstatisticians.com'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Commemorative logotipo - 50 years ICEx UFMG',
      'name': 'icex-ufmg-50-anos',
      'url': 'icex-ufmg-50-years',
      'color': '#9a1818',
      'description': 'Logo design',
      'tags': [
        'design',
        'logo',
        'illustration'
      ],
      'date': '2018-06-01',
      'text': [
        'Upon completing 50 years, the <a href="http://www.icex.ufmg.br" target="_blank"> UFMG Institute of Exact Sciences </a> opened a contest to create a logo to commemorate this date. I participated and had the honor of being the winner, with the work that can be seen below. ',
        'In this project, the concept present in the 50th anniversary seal of the Institute of Exact Sciences combines the current format of the institute"s brand with geometric elements, which are present throughout the building and in the courses offered by ICEx.'
      ],
      'imageCover': 'project_34.png',
      'imageGallery': 'g_project_34.png',
      'fid': 34,
      'images': [
        '34_1.png',
        '34_2.png',
        '34_3.png',
        '34_4.png',
        '34_5.png',
        '34_6.png',
        '34_7.png',
        '34_8.png',
        '34_9.png',
        '34_10.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://www.icex.ufmg.br/'
        ],
        'bhc': 'https://www.behance.net/gallery/67763503/ICEx-UFMG-50-years',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Biblexplorer App',
      'name': 'biblexplorer-app',
      'url': 'biblexplorer-app',
      'color': '#008eba',
      'description': 'Android and iOS application',
      'tags': [
        'app',
        'programming',
        'ui design'
      ],
      'date': '2017-07-30',
      'text': [
        'Bible application, developed by me using Ionic Framework, published on Google Play and the App Store.'
      ],
      'imageCover': 'project_55.png',
      'imageGallery': 'g_project_55.png',
      'fid': 55,
      'images': [
        '55_1.jpg',
        '55_2.jpg',
        '55_3.jpg',
        '55_4.jpg',
        '55_5.jpg',
        '55_6.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://biblexplorer.com.br/'
        ],
        'bhc': 'https://www.behance.net/gallery/85185851/Biblexplorer-aplicativo',
        'app_and': 'https://play.google.com/store/apps/details?id=com.br.biblexplorer',
        'app_ios': 'https://itunes.apple.com/us/app/biblexplorer/id1144180913',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'Sisu UFMG - Data visualization',
      'name': 'dataviz-sisu',
      'url': 'dataviz-sisu',
      'color': '#3d6b72',
      'description': 'Sisu UFMG 2018 data visualization',
      'tags': [
        'dataviz'
      ],
      'date': '2018-08-01',
      'text': [
        'I did a small data visualization project with the information from Sisu UFMG 2018.'
      ],
      'imageCover': 'project_40.png',
      'imageGallery': 'g_project_40.png',
      'fid': 40,
      'images': [
        '40_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://homepages.dcc.ufmg.br/~maria.luisa/vis/sisu/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/sisu-data'
      }
    },
    {
      'title': 'BitGirls',
      'name': 'bitgirls',
      'url': 'bitgirls',
      'color': '#ce7477',
      'description': 'Logo design',
      'tags': [
        'design',
        'logo'
      ],
      'date': '2018-07-16',
      'text': [
        'I worked on the creation of the BitGirls brand. BitGirls is an initiative by the Department of Computer Science to encourage girls to enter Computer courses. '
      ],
      'imageCover': 'project_39.png',
      'imageGallery': 'g_project_39.png',
      'fid': 39,
      'images': [
        '39_1.png',
        '39_2.png',
        '39_3.png',
        '39_4.png',
        '39_5.png',
        '39_6.png',
        '39_7.png',
        '39_8.png',
        '39_9.png',
        '39_10.png',
        '39_11.png',
        '39_12.png',
        '39_13.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://www.bitgirls.dcc.ufmg.br/'
        ],
        'bhc': 'https://www.behance.net/gallery/71344311/Bit-Girls',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Advertising materials for Trixel company',
      'name': 'trixel-folder',
      'url': 'trixel-folder',
      'color': '#004865',
      'description': 'Project made for Trixel company. There were several promotional material, below is the model of the developed folder. ',
      'tags': [
        'design'
      ],
      'date': '2019-11-05',
      'text': [
        'I carried out as a freelancer a project that consisted of developing some promotional materials for the company Trixel.'
      ],
      'imageCover': 'project_57.png',
      'imageGallery': 'g_project_57.png',
      'fid': 57,
      'images': [
        '57_1.jpg',
        '57_2.jpg',
        '57_3.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'Visualization - Minimum Wage in Brazil',
      'name': 'minimum-wages-brazil',
      'url': 'minimum-wages-brazil',
      'color': '#4971be',
      'description': 'Interactive visualization developed with data from IBGE',
      'tags': [
        'dataviz',
        'programming',
        'ui design'
      ],
      'date': '2019-02-20',
      'text': [
        'I am developing a small experiment with data on the minimum wage in Brazil. The objective is to put into practice some good programming practices in the development of data visualization with D3Js. ',
        'This project, although simple, applies some practices in D3, developed from scratch:',
        '<ul> <li> Simple line graph with points; </li> <li> Grid of lines to the bottom of the graph; </li> <li> Hover on points; </li> <li> Description in some items such as default; </li> <li> ES6 + Js; </li> <li> Modularization of functions; </li> </ul> '
      ],
      'imageCover': 'project_48.png',
      'imageGallery': 'g_project_48.png',
      'fid': 48,
      'images': [
        '48_1.png',
        '48_2.png',
        '48_3.gif'
      ],
      'company': 'Student',
      'links': {
        'site': [
          'https://marialuisacp.github.io/minimum-wages-brazil/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/minimum-wages-brazil'
      }
    },
    {
      'title': 'Agent Sofia',
      'name': 'sofia-social-worker',
      'url': 'sofia-social-worker',
      'color': '#2e7d7c',
      'description': 'Application developed at The Big Hackathon at Campus Party Brasil 12 in São Paulo.',
      'tags': [
        'design',
        'illustration',
        'logo',
        'programming',
        'system',
        'site',
        'ui design',
        'dataviz'
      ],
      'date': '2019-02-12',
      'text': [
        'Project for The Big Hackathon at Campus Party Brasil 12, with which we won 1st place in the Human Rights category and 3rd place in the final. In the hackathon six themes were presented and the teams had to present solutions for one or more of the proposed themes. ',
        'In this work we have created a solution to an issue related to Human Rights, involving children at risk.',
        'We made a virtual social worker called Sofia. She talks to people via an automatic chatbot and collects information regarding vulnerable children. The chat was done by Blip. In addition, we developed a web application that displays the data sent by chat on a map. This project was developed with <a href="https://github.com/hugoiuri" target="_blank"> Hugo Iuri </a>, <a href = "https://github.com/kpazfagundes" target = "_blank"> Karine Paz Fagundes </a> and <a href="https://www.linkedin.com/in/estela-kanashiki-1714073a/" target="_blank"> Estela Kanashiki </a> in February 2019 at CPBR12 in São Paulo. '
      ],
      'imageCover': 'project_46.png',
      'imageGallery': 'g_project_46.png',
      'fid': 46,
      'images': [
        '46_1.PNG',
        '46_2.png',
        '46_3.PNG'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://agentesofia-a9775.firebaseapp.com'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/agente-sofia-project-web/'
      }
    },
    {
      'title': 'E-commerce UI / UX Study',
      'name': 'e-commerce-ui-ux',
      'url': 'e-commerce-ui-ux',
      'color': '#0068ad',
      'description': 'Project made for the study and practice of UI / UX of the sales system.',
      'tags': [
        'design',
        'site',
        'ui design'
      ],
      'date': '2019-11-10',
      'text': [
        'Fictitious project, for studying UI / UX using Adobe Xd and Adobe Photoshop.'
      ],
      'imageCover': 'project_56.png',
      'imageGallery': 'g_project_56.png',
      'fid': 56,
      'images': [
        '56_1.jpg',
        '56_2.jpg',
        '56_3.jpg',
        '56_4.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': 'https://www.behance.net/gallery/87976699/E-commerce-UIUX?',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'ER 2019',
      'name': 'er-2019',
      'url': 'er-2019',
      'color': '#63886d',
      'description': 'Visual identity of the ER - 2019 event',
      'tags': [
        'design',
        'illustration',
        'logo'
      ],
      'date': '2018-08-01',
      'text': [
        'I worked on the development of the visual identity of the event ER - 2019 International database event (Entity-Relationship) that in 2019 will take place in Salvador, BA.'
      ],
      'imageCover': 'project_41.png',
      'imageGallery': 'g_project_41.png',
      'fid': 41,
      'images': [
        '41_1.png',
        '41_2.png',
        '41_3.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://www.inf.ufrgs.br/er2019/'
        ],
        'bhc': 'https://www.behance.net/gallery/71344363/ER-2019',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'The star wars project',
      'name': 'the-stars-wars-project',
      'url': 'the-stars-wars-project',
      'color': '#192139',
      'description': 'React.js application developed using the Star Wars API.',
      'tags': [
        'design',
        'illustration',
        'programming',
        'site',
        'ui design'
      ],
      'date': '2019-01-09',
      'text': [
        'I developed a simple application in ReactJs to calculate the number of stops that a Star Wars spacecraft has to make given a given distance. The backend was done in NodeJs and the data was extracted from the <a href="https://swapi.co/" target="_blank"> Star Wars API </a> (public API). ',
        'Besides, I also designed the ships, yes, I designed them all. You can check it out at Behance. '
      ],
      'imageCover': 'project_47.png',
      'imageGallery': 'g_project_47.png',
      'fid': 47,
      'images': [
        '47_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://the-stars-wars-api.firebaseapp.com/'
        ],
        'bhc': 'https://www.behance.net/gallery/77017047/Starships-Star-Wars',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/the-star-wars-api'
      }
    },
    {
      'title': 'Frequences of terms on Bible',
      'name': 'dataviz-frequence',
      'url': 'dataviz-frequence',
      'color': '#666699',
      'description': 'Data view',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2018-07-20',
      'text': [
        'Visualization implementation in D3.js'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_44.png',
      'fid': 44,
      'images': [
        '44_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'http://bl.ocks.org/marialuisacp/086d4fa672854281b0b3d0de2326f28d'
      }
    },
    {
      'title': 'Template - Line chart with points',
      'name': 'dataviz-line-chart',
      'url': 'dataviz-line-chart',
      'color': '#ffa04c',
      'description': 'Data view',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2018-10-01',
      'text': [
        'Visualization implementation in D3.js'
      ],
      'imageCover': 'project_42.png',
      'imageGallery': 'g_project_42.png',
      'fid': 42,
      'images': [
        '42_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://bl.ocks.org/marialuisacp/514bfcfe4e5e58b27598358f70cc5adf'
      }
    },
    {
      'title': 'UI E-commerce',
      'name': 'ui-ecommerce',
      'url': 'ui-ecommerce',
      'color': '#48484e',
      'description': 'Design and implementation of interfaces for e-commerce',
      'tags': [
        'ui design',
        'site',
        'programming'
      ],
      'date': '2018-06-01',
      'text': [
        'I carried out this project as a technical test in a selection process. In the test I did the design and implementation of the interfaces using Angular 4. To persist the data I used MongoDB and the NodeJs backend. '
      ],
      'imageCover': 'project_37.png',
      'imageGallery': 'g_project_37.png',
      'fid': 37,
      'images': [
        '37_1.png',
        '37_2.png',
        '37_3.png',
        '37_4.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://uiecommerceminu.000webhostapp.com/'
        ],
        'bhc': 'https://www.behance.net/gallery/71348115/UI-e-commerce',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/uiecommerce'
      }
    },
    {
      'title': 'Data Visualization reports',
      'name': 'dataviz-reports',
      'url': 'dataviz-reports',
      'color': '#0865af',
      'description': 'I developed visualizations with product reporting data',
      'tags': [
        'dataviz',
        'programming',
        'ui design'
      ],
      'date': '2018-12-06',
      'text': [
        'Independently, I created some reports for the company I work on to present my lecture on Data Visualization together. For data privacy reasons, full reports will not be displayed here. '
      ],
      'imageCover': 'project_49.png',
      'imageGallery': 'g_project_49.png',
      'fid': 49,
      'images': [
        '49_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'Breastfeeding primer',
      'name': 'breastfeeding-guide',
      'url': 'breastfeeding-guide',
      'color': '#bd4ea7',
      'description': 'Illustration work',
      'tags': [
        'illustration',
        'design'
      ],
      'date': '2018-01-20',
      'text': [
        'I worked on a project for the Department of Nutrition at UFVJM (Federal University of the Valleys of Jequitinhonha and Mucuri), where my goal was to develop illustrations for a booklet on breastfeeding.',
        'At the end of the work, the booklet was printed and distributed at Hospital Nossa Senhora da Saúde. My contribution to the project was to make the illustrations and also the layout. Some of the drawings are below. '
      ],
      'imageCover': 'project_33.png',
      'imageGallery': 'g_project_33.png',
      'fid': 33,
      'images': [
        '33_21.png',
        '33_22.png',
        '33_24.png',
        '33_23.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': 'https://www.behance.net/gallery/71347969/Cartilha-de-Aleecimento-Materno',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Graphs - Dijkstra algorithm and triangular inequality',
      'name': 'dijkstra-algorithm',
      'url': 'dijkstra-algorithm',
      'color': '#313131',
      'description': 'Implementation of algorithms for graphs: Dijkstra and triangular inequality',
      'tags': [
        'programming'
      ],
      'date': '2018-04-15',
      'text': [
        'This work presents the practical project of Modeling in Graphs of the discipline Processing and Analysis of Algorithms of the Graduate Program in Computer Science at the Federal University of Minas Gerais. In it, I implement a solution to a computational problem modeled in graphs using the Dijkstra Algorithm and the principle of triangular inequality. ',
        'The code was developed in Java and is public. The project description and documentation can be found at the <a href="https://github.com/marialuisacp/Grafo/blob/master/documentacao.pdf" target="_blank"> link </a>. '
      ],
      'imageCover': '',
      'imageGallery': 'g_project_52.png',
      'fid': 52,
      'images': [
        '52_1.png'
      ],
      'company': 'Student',
      'links': {
        'site': [
          'https://github.com/marialuisacp/Grafos/blob/master/documentacao.pdf'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/Graphics'
      }
    },
    {
      'title': 'System dashboards',
      'name': 'system-dashboards',
      'url': 'system-dashboards',
      'color': '#ff612e',
      'description': 'System design and front end',
      'text': [
        'In this project I worked with the design and implementation of the system interfaces, creation and modeling of the issued reports and dashboards.',
        'For privacy reasons, only a few dashboards are being shown and the system\'s name / logo has also been hidden.'
      ],
      'date': '2017-05-07',
      'tags': [
        'system',
        'programming',
        'design',
        'ui design',
        'dataviz'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_27.png',
      'fid': 27,
      'images': [
        '27_1.png',
        '27_2.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'NLP - Natural Language Processing (Experiment Results in D3.js)',
      'name': 'dataviz-nlp-experiment',
      'url': 'dataviz-nlp-experiment',
      'color': '#333',
      'description': 'Data view',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2018-04-01',
      'text': [
        'In this project I developed a visualization with data from Word2Vec - Neural Word Embedding with data from the Brazilian Wikipedia.'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_43.png',
      'fid': 43,
      'images': [
        '43_1.png',
        '43_2.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/Wikipedia-Data-Force-Directed-Graph-'
      }
    },
    {
      'title': 'Visualization - Total Business Inventories and Sales Data',
      'name': 'total-business-inventories-and-sales-data',
      'url': 'total-business-inventories-and-sales-data',
      'color': '#5fa952',
      'description': 'Time series visualizations developed with D3Js v3, using data from the U.S. Census Bureau.',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2019-06-23',
      'text': [
        'Development of two visualizations, bar graph and line graph to visualize the time series with data (Total Business Inventories and Sales Data). In this project I used: ',
        '<ul> <li> D3.js (v3); </li> <li> ECMAScript 6; </li> <li> ES6 + Js; </li> <li> Total Business Inventories and Sales Data (<a href = "https://www.kaggle.com/census/total-business-inventories-and-sales-data" target = "_ blank">Available in Kaggle</a>); </li> </ul> '
      ],
      'imageCover': '',
      'imageGallery': 'g_project_54.png',
      'fid': 54,
      'images': [
        '54_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://marialuisacp.github.io/labviz---time-serie-Total-Business-Inventories-and-Sales-Data/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/labviz---time-serie-Total-Business-Inventories-and-Sales-Data'
      }
    },
    {
      'title': 'App Help Festival',
      'name': 'app-help-festival',
      'url': 'app-help-festival',
      'color': '#5f93bb',
      'description': 'Application for Help Festival 2017',
      'text': [
        'I developed an informational app for a Christian festival that takes place in Campo Mourão, the Help Festival.',
        'The objective of the event is to take speakers, workshops with themes related to missions in a weekend, in addition to bands and artists.',
        'The application was developed for Android and iOS devices with the aim of bringing information regarding the event such as location, schedule, speakers, map, contact form and event evaluation. The application is available in stores. '
      ],
      'date': '2017-01-09',
      'tags': [
        'app',
        'programming',
        'design',
        'ui design'
      ],
      'imageCover': 'project_30.png',
      'imageGallery': 'g_project_30.png',
      'fid': 30,
      'images': [
        '30_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://www.helpfestival.com/'
        ],
        'bhc': '',
        'app_and': 'https://play.google.com/store/apps/details?id=com.ionicframework.helpfestival895626',
        'app_ios': 'https://itunes.apple.com/us/app/help-festival/id1286950329?l=en&ls=1&mt=8',
        'comp': ''
      }
    },
    {
      'title': 'CGRIFES site',
      'name': 'site-cgrifes',
      'url': 'site-cgrifes',
      'color': '#6aa9de',
      'description': 'Website development',
      'text': [
        'In contract with the Department of International Relations at the Federal University of Minas Gerais, I worked on the development of the website for CGRIFES - IFES College of International Relations Managers.',
        'The website was developed in wordpress.'
      ],
      'date': '2017-27-09',
      'tags': [
        'site',
        'design',
        'ui design'
      ],
      'imageCover': 'project_10.png',
      'imageGallery': 'g_project_10.png',
      'fid': 10,
      'images': [
        '10_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Visualization of population swelling in Brazil',
      'name': 'dataviz-br-population-cities',
      'url': 'dataviz-br-population-cities',
      'color': '#00958d',
      'description': 'Data visualization with information on population distribution in Brazil',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2019-03-01',
      'text': [
        'In this project I am working with data from IBGE and I showed on a map the distribution of the Brazilian population in cities.',
        'One of the goals of this project was to practice using the D3js library with Google Maps.'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_51.png',
      'fid': 51,
      'images': [
        '51_1.png',
        '51_2.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://bl.ocks.org/marialuisacp/64fe2449243a8043946d4f5ce4906e6d'
      }
    },
    {
      'title': 'João Camilo Camisa',
      'name': 'joao-camilo-shirts',
      'url': 'joao-camilo-shirts',
      'color': '#486256',
      'description': 'Brand designer',
      'text': [
        'In this project I worked on building the João Camilo brand.',
        'João Camilo is the name of the men\'s fashion brand, focused on shirts. The João Camilo brand presents itself as an innovative shirt, with a focus on a differentiated and attuned public, which seeks something more than the functional. Our commitment starts with our employees and ends with the satisfaction of our customers. For this, we provide a differentiated and fun shopping experience. ',
        'The choice of the name João Camilo was inspired by the dog (English bulldog) of one of the partners, so the figure of the dog is an element that refers to the essence of the brand.',
        'Although he uses the dog as a symbolic element, it should not convey the idea of ​​a domestic dog, but bring a wild, angry look, the traditional bulldog.'
      ],
      'date': '2017-10-01',
      'tags': [
        'design',
        'logo'
      ],
      'imageCover': 'project_03.png',
      'imageGallery': 'g_project_03.png',
      'fid': 3,
      'images': [
        '3_7.png',
        '3_9.png',
        '3_5.png',
        '3_6.png',
        '3_1.png',
        '3_2.png',
        '3_4.png',
        '3_10'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': 'https://www.behance.net/gallery/71348615/Joao-Camilo-Camisas',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'DRI / UFMG sites',
      'name': 'sites-dri-ufmg',
      'url': 'sites-dri-ufmg',
      'color': '#b70e0c',
      'description': 'Development of institutional websites',
      'text': [
        'I worked on the development of three institutional websites for the Department of International Relations at the Federal University of Minas Gerais.',
        'The sites went to the Centers for Latin American Studies, the Center for European Studies and the Center for African Studies. All were developed in wordpress following the guidelines given by the University\'s Communication Center. '
      ],
      'date': '2017-05-07',
      'tags': [
        'site',
        'design',
        'ui design'
      ],
      'imageCover': 'project_15.png',
      'imageGallery': 'g_project_15.png',
      'fid': 15,
      'images': [
        '15_1.png',
        '15_2.png',
        '15_3.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://www.ufmg.br/dri/cee/',
          'https://www.ufmg.br/dri/cea/',
          'https://www.ufmg.br/dri/cela/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Grupo Joyfully',
      'name': 'grupo-joyfully',
      'url': 'grupo-joyfully',
      'color': '#7c59c2',
      'description': 'Brand designer',
      'text': [
        'In this project I worked on building the brand Joyfully.',
        'Joyfully is the name of the group of musicians that performs at weddings, business events and ceremonies that need classical music. The visual identity of the group was designed to bring together visual elements that represent what the group expresses. The term Joyfully in English expresses the feeling of a person being very happy. Thus the visual identity of the group was designed to bring joy and creativity to the group. ',
        'The clef was used to represent the group\'s musicality by replacing the letter J in the logo. In addition, the word Joy was highlighted \'Joy\'.',
        'The stages of creation and the end result of the composition of the elements are shown below.'
      ],
      'date': '2017-05-07',
      'tags': [
        'design',
        'logo'
      ],
      'imageCover': 'project_04.png',
      'imageGallery': 'g_project_04.png',
      'fid': 4,
      'images': [
        '4_1.png',
        '4_3.png',
        '4_2.jpg',
        '4_4.png',
        '4_5.png',
        '4_6.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://grupojoyfully.com/'
        ],
        'bhc': 'https://www.behance.net/gallery/71344817/Joyfully',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': '1920 arts',
      'name': '1920-arts',
      'url': '1920-arts',
      'color': '#df1980',
      'description': 'Drawings that bring some reflections',
      'text': [
        'I made some drawings with some creative phrase.'
      ],
      'date': '2017-10-01',
      'tags': [
        'design',
        'illustration'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_45.png',
      'fid': 45,
      'images': [
        '45_1.png',
        '45_2.png',
        '45_3.png',
        '45_4.png',
        '45_5.png',
        '45_6.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://1920artes.tumblr.com/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Marketing Professional',
      'name': 'markging-professional',
      'url': 'markging-professional',
      'color': '#476c96',
      'description': 'Logo designer',
      'tags': [
        'design',
        'logo'
      ],
      'date': '2017-05-07',
      'text': [
        'This project consists in the development of a logo for the Marketing Professional project.'
      ],
      'imageCover': 'project_05.png',
      'imageGallery': 'g_project_05.png',
      'fid': 5,
      'images': [
        '5_1.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'https://markgingprofessional.com'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Aposte Agora',
      'name': 'aposte-agora',
      'url': 'aposte-agora-app',
      'color': '#6ca137',
      'description': 'Betting app',
      'tags': [
        'system',
        'programming',
        'design',
        'app',
        'ui design'
      ],
      'date': '2017-05-07',
      'text': [
        'This project consists of the development of a betting application. Both the project name and the brand have been changed by the project owners. In the project I worked with the design of the application screens. '
      ],
      'imageCover': 'project_21.png',
      'imageGallery': 'g_project_21.png',
      'fid': 21,
      'images': [
        '21_1.png',
        '21_2.png',
        '21_3.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'ChoferApp',
      'name': 'chofer-app',
      'url': 'chofer-app',
      'color': '#bc2037',
      'description': 'Application screens development',
      'text': [
        'ChoferApp is an application also known as Uber Clone. It is a mobile application with the implementation of a transport management system. This app is a model that can apply the same business model to other transport demands. ',
        'The application is available for Android and iOS devices. I worked on the implementation of the application interfaces on both platforms, according to the prototyped design. I also helped with the interfaces, giving suggestions for improvements. '
      ],
      'date': '2017-05-07',
      'tags': [
        'app',
        'design',
        'ui design'
      ],
      'imageCover': 'project_28.png',
      'imageGallery': 'g_project_28.png',
      'fid': 28,
      'images': [
        '28_1.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'http://codificar.com.br/produtos/aplicativo-uber-clone-script/'
        ],
        'bhc': '',
        'app_and': 'https://play.google.com/store/apps/details?id=com.br.chofer',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Graduate sites',
      'name': 'sites-pos-graduacao-ufmg',
      'url': 'sites-postgraduate-ufmg',
      'color': '#d03f4a',
      'description': 'Website development',
      'tags': [
        'site'
      ],
      'date': '2017-05-07',
      'text': [
        'I was hired as a freelance professional by Cedecom (UFMG Communication Center) for the development of new sites for the University\'s Graduate programs.My role was to configure some sites on the servers and customize the templates.The websites were made using wordpress. '
      ],
      'imageCover': 'project_29.png',
      'imageGallery': 'g_project_29.png',
      'fid': 29,
      'images': [
        '29_1.jpg',
        '29_2.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://ppgcc.dcc.ufmg.br/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Population Time Series Data',
      'name': 'population-time-series-data',
      'url': 'population-time-series-data',
      'color': '#b38ed3',
      'description': 'Visualization developed with D3Js v4.',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2019-06-23',
      'text': [
        'Development of simple visualization (Area graph) with D3Js, using:',
        '<ul> <li> D3.js (v4); </li> <li> ECMAScript 6; </li> <li> ES6 + Js; </li> <li> Data Population Time Series. (<a href="https://www.kaggle.com/census/population-time-series-data" target="_blank">Available in Kaggle</a>); </li> </ul> '
      ],
      'imageCover': '',
      'imageGallery': 'g_project_53.png',
      'fid': 53,
      'images': [
        '53_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://marialuisacp.github.io/labviz---Population-Time-Series-Data/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/labviz---Population-Time-Series-Data'
      }
    },
    {
      'title': 'DocDoc',
      'name': 'docdoc',
      'url': 'docdoc-app',
      'color': '#666699',
      'description': 'Application and system design and development',
      'text': [
        'DocDoc is a social network made for doctors. The main objective is to provide a network of contacts between them, in addition to the disclosure of job vacancies, courses, exams by health institutions. ',
        'I did this work for Codificar, and I worked on it in the design of the app, in addition to drawing the icon of the app.'
      ],
      'date': '2017-05-07',
      'tags': [
        'app',
        'system',
        'programming',
        'design',
        'logo',
        'ui design'
      ],
      'imageCover': 'project_07.png',
      'imageGallery': 'g_project_07.png',
      'fid': 7,
      'images': [
        '7_1.png',
        '7_3.png',
        '7_4.png',
        '7_5.png',
        '7_6.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Word2Vec: Analyzing Gutemberg books',
      'name': 'word2vec-Livros-do-gutemberg',
      'url': 'word2vec-gutemberg-books',
      'description': 'Natural language processing with book data',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2017-09-10',
      'text': [
        'This work aimed to implement language models for a book library, in order to apply the Word2Vec method. The book base was taken from the Gutemberg website (https://www.gutenberg.org/). The list of books can be found at the <a href="http://homepages.dcc.ufmg.br/~maria.luisa/files/nlp/books.txt" target="_blank"> link. ',
        'Word2vec is a neural network that processes text. In summary, it can be said that the purpose of this method is to learn similar terms based on the context. Its input is a body of text and the output is a set of vectors: word characteristic vectors. Word2vec is not a deep neural network, it transforms text into a numeric format that deep networks can understand. In this project the method was used taking as a database, the books of Gutemberg. ',
        'Pre-processing was carried out by placing words in lowercase and separating them from punctuation marks (commas, accents, periods, exclamation, etc.). No Stemming method was used, so the word variations (plurals, verbal conjugation, among others) were not unified. Stop words were removed in the analysis. The list of stop words can be accessed on the <a href="http://homepages.dcc.ufmg.br/~maria.luisa/files/nlp/stop_words.txt" target="_blank"> link </a> . ',
        'Two implementations of Word2Vec were used in the analyzes contained in this work. The first is an <a href="https://github.com/tmikolov/word2vec" target="blank"> open project contained on Github </a> developed by Google in C language (<a href = "https: //code.google.com/archive/p/word2vec/ "target =" _ blank "> See code documentation </a>). ',
        'The second implementation that was also very useful was the one belonging to <a href="https://deeplearning4j.org/word2vec.html" target="_blank"> Deeplearning4j library </a>. This second was used to observe and analyze the books individually and generate visualizations that would facilitate the analysis. The visualizations were made using the D3Js library. ',
        'The way this work was developed is just an option on how to encode words in numerical representations. The idea explored by word2vec is to recognize a word by the company it maintains, to know what a word means by the context in which it is inserted. So each word is represented by a vector. The distance between these vectors reveals how strong the connection between words is in the learned context. Given the word vectors, it is possible to build language models for each book, after all, each book has its own language. ',
        'In order to discover language models of books and to be able to carry out analyzes on them, the method was used in each book, observing the most frequent words in each one. The visualization below shows, through a graph, an example of relationships between the words found in one of the books. ',
        '<img src = "../images/projects/50_1.png" />',
        'Words and relations in the book: Autobiography_of_Benjamin_Franklin',
        'Nodes represent words and edges represent words',
        'The font size represents the frequency, where you can see two font sizes in the words:',
        '<ul> <li> large: they are among the 20 most frequent terms; </li> <li> small: only words; </li> </ul>',
        'In order to compare the language models in books, an experiment was carried out with the most frequent words in books. The 20 most frequent words in each book were used, removing the stop words. As the list of stop words was not very long and specific, some words like adverbs ended up on the list and this was even a positive point. At the end, some stop words were added to balance the distribution between specifics of the books x common words in the books (stop words). Thus the sample contains both important words in the context of the book, as well as generic words that are found in all books. It is worth mentioning that it is possible that the analysis may not be so fair, considering that the size of the books varies a lot. ',
        'Some tests were carried out in order to obtain the best sample size. The final sample contained 75 words. ',
        'This sampling was used as a basis for generating a matrix for each book, in order to compare the distance between the books. The decision to use frequent words in each book was due to the fact that the differences between the themes of the book are evident. Thus collecting the most frequent words, it is possible to assume that an important part of each book would be contained in the sample. I must point out that the most frequent words in a given book will possibly (and probably) be contained in another book so there will potentially be a relationship between the words (whether big or small). ',
        'In the square distance matrix, the dimensions of the matrix represent the distance between word pairs. The words are those contained in the sample and will be compared one by one, generating the distance matrix (nxn). ',
        'Based on the results found, an analysis was made of some of the authors contained in the used book base.',
        '<span class = "featured-term"> Agatha Christie </span>',
        'The books by Agatha Christie: The Secret Adversary and The Mysterious Affair at Styles.',
        'Agatha\'s writing in the books cited above shows a variation in the writing style of the book. In The Mysterious Affair at Styles the author presented a more "melancholy" text. Notice how closely linked the terms are. In The Secret Adversary, however, the words seem to have received greater independence. ',
        'The observed distance between the books was: 1.1394',
        '<p class = "center"> <img src = "../images/projects/50_2.jpg" /> </p>',
        '<p class = "center"> Model graphs seen in <span class = "italic"> The Secret Adversary </span> </p>',
        '<p class = "center"> <img src = "../images/projects/50_3.jpg" /> </p>',
        '<p class = "center"> Model charts seen in <span class = "italic"> The Mysterious Affair at Styles </span> </p>',
        '<span class = "featured-term"> Lewis Carroll </span>',
        'Lewis Carroll\'s books contained in the base were Alice "s Adventures in Wonderland and Through the Looking-Glass. The theme of the books may have contributed to the distance observed in them. The books do not have much apparent relationship. Perhaps in each of their authorship try to present new themes regarding both the content and the way of writing. The storyline of Alice "s Adventures in Wonderland seems to be very peculiar, presenting a considerable relation of the words contained in the text (with each other). This phenomenon also occurs in Through the Looking-Glass although the texts are not alike. The observed distance between the books was: 5.67594 ',
        '<p class = "center"> <img src = "../images/projects/50_4.jpg" /> </p>',
        '<p class = "center"> Model charts seen in <span class = "italic"> Alice "s Adventures in Wonderland </span> </p>',
        '<p class = "center"> <img src = "../images/projects/50_5.jpg" /> </p>',
        '<p class = "center"> Model graphs observed in <span class = "italic"> Through the Looking-Glass </span> </p>',
        '<span class = "featured-term"> William Shakespeare </span>',
        'From the analyzed Shakespeare books, (they are The Complete Works of William, The Tragedy of Romeo and Juliet and The Complete Works of William Shakespeare) we were able to analyze an apparent relationship between them although Shakespeare appears to show a significant difference in writing (perhaps due to different themes from your books). Shakspare\'s books also resemble other literature. The similarity of his books in relation to the others compared was not apparent. '
      ],
      'imageCover': '',
      'imageGallery': 'g_project_50.png',
      'fid': 50,
      'images': [],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'Kangaroo Kebab',
      'name': 'kangaro-kebab',
      'url': 'kangaro-kebab-website',
      'color': '#e23c18',
      'description': 'Website development',
      'tags': [
        'site'
      ],
      'date': '2017-05-07',
      'text': [
        'Kangaroo Kebabs is a fast food chain present in several countries. I worked as a freelancer for the Megaphone Agency on the front end of the site for Kangaroo Kebabs. In the project I developed the responsive screens according to the prototype that was passed to me. '
      ],
      'imageCover': 'project_25.png',
      'imageGallery': 'g_project_25.png',
      'fid': 25,
      'images': [
        '25_3.jpg',
        '25_2.jpg',
        '25_1.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://kangarookebabs.com.br/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Lagoa Flats',
      'name': 'lagoa-flats',
      'url': 'site-lagoa-flats',
      'color': '#013334',
      'description': 'Graphic design',
      'tags': [
        'design',
        'logo'
      ],
      'date': '2015-01-22',
      'text': [
        'I worked on the development of the Lagoa Flats logo, a flat rental service.'
      ],
      'imageCover': 'project_12.png',
      'imageGallery': 'g_project_12.png',
      'fid': 12,
      'images': [
        '12_1.jpg',
        '12_2.png',
        '12_3.png',
        '12_4.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Appjuridico',
      'name': 'appjuridico',
      'url': 'appjuridico-app',
      'color': '#32737e',
      'description': 'Application design and development',
      'text': [
        'In this application I worked with the design of the interfaces and the development of the AppJurídico application. This app was made for lawyers who want to find legal correspondents. ',
        'It was done in 2015 by Codificar and I was present in the development team. In the project I worked doing meetings with the client, in order to understand the demand and model the application. I made the prototype of the screens and developed the application. Customer feedbacks were positive. ',
        'The application was made with Ionic Framework.'
      ],
      'date': '2015-10-01',
      'tags': [
        'app',
        'programming',
        'design',
        'ui design'
      ],
      'imageCover': 'project_11.png',
      'imageGallery': 'g_project_11.png',
      'fid': 11,
      'images': [
        '11_1.png',
        '11_2.jpg'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'https://appjuridico.com.br'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Construgab',
      'name': 'construgab',
      'url': 'construgab-design',
      'color': '#be9824',
      'description': 'Graphic design',
      'tags': [
        'design',
        'logo'
      ],
      'date': '2017-05-07',
      'text': [
        'This project consisted of creating the Construgab brand, a construction company in Belo Horizonte.',
        'I worked on creating the brand and promotional materials, such as printed material and website. Unfortunately the company has now closed its services. '
      ],
      'imageCover': 'project_08.png',
      'imageGallery': 'g_project_08.png',
      'fid': 8,
      'images': [
        '8_1.png',
        '8_2.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'CelebraFotos',
      'name': 'celebrates-fotos',
      'url': 'celebra-fotos-app',
      'color': '#1878a6',
      'description': 'Smart TV app development',
      'text': [
        'This was an application made for Smart TV. It\'s an application similar to instagram, but for a TV. ',
        'It works like a photo booth. Users take photos and edit on touch screen TV. ',
        'I worked on the design and development of the interfaces. The tool used was WinJs, a native Javascript Framework for creating applications for windows. '
      ],
      'date': '2015-08-10',
      'tags': [
        'system',
        'programming',
        'design',
        'ui design'
      ],
      'imageCover': 'project_17.png',
      'imageGallery': 'g_project_17.png',
      'fid': 17,
      'images': [
        '17_1.png',
        '17_2.png',
        '17_3.png',
        '17_4.png',
        '17_5.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'https://codificar.com.br/portfolios/celebra-fotos/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Cash flow control',
      'name': 'cash-flow-control',
      'url': 'cash-flow-control',
      'color': '#336699',
      'description': 'Cash flow control system',
      'tags': [
        'design',
        'system',
        'programming',
        'dataviz',
        'ui design'
      ],
      'date': '2017-05-07',
      'text': [
        'This was a simple project developed by me in 2015 as a task for a company\'s selection process. It is a cash flow control system, where it is possible to enter expenses and earnings in a given period and the system should return the balance and a graph with analyzes. ',
        'The work was successfully approved. To develop it I used AngularJs and the graph was done in D3Js. '
      ],
      'imageCover': 'project_16.png',
      'imageGallery': 'g_project_16.png',
      'fid': 16,
      'images': [
        '16_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/Cash-Flow'
      }
    },
    {
      'title': 'UFMG on the network',
      'name': 'ufmg-na-rede',
      'url': 'ufmg-na-rede',
      'color': '#ca171d',
      'description': 'Website development',
      'tags': [
        'site',
        'ui design'
      ],
      'date': '2013-09-08',
      'text': [
        'In this project I worked on the development of a news portal for the University. In fact it is like a blog with daily posts, which gathers links to various news related to the University. UFMG is a huge university, with many institutes, departments, colleges, and specific areas. So the objective of "UFMG na rede" is to gather in one place, the links of the main information and daily news. ',
        'I worked with the development of the wordpress website, and also with the development of wordpress plugins, and specific types of post.'
      ],
      'imageCover': 'project_26.png',
      'imageGallery': 'g_project_26.png',
      'fid': 26,
      'images': [
        '26_1.png'
      ],
      'company': 'Cedecom - Communication Center, Federal University of Minas Gerais',
      'links': {
        'site': [
          'https://www.ufmg.br/narede'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'https://ufmg.br/comunicacao/sobre-o-cedecom'
      }
    },
    {
      'title': 'UFMG Meu Lugar',
      'name': 'site-ufmg-meu-lugar',
      'url': 'site-ufmg-meu-lugar',
      'color': '#ff3859',
      'description': 'Website development',
      'tags': [
        'site'
      ],
      'date': '2017-05-07',
      'text': [
        'I worked on the implementation of the UFMG site "Meu Lugar" (My place), done in wordpress by the Communication Center of the Federal University of Minas Gerais.'
      ],
      'imageCover': 'project_20.png',
      'imageGallery': 'g_project_20.png',
      'fid': 20,
      'images': [
        '20_1.png'
      ],
      'company': 'Cedecom - Communication Center, Federal University of Minas Gerais',
      'links': {
        'site': [
          'https://www.ufmg.br/meulugar'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'https://ufmg.br/comunicacao/sobre-o-cedecom'
      }
    },
    {
      'title': 'ijunior institutional website',
      'name': 'site-ijunior',
      'url': 'site-ijunior',
      'color': '#00548e',
      'description': 'Website development',
      'tags': [
        'site',
        'ui design'
      ],
      'date': '2017-05-07',
      'text': [
        'As a member of UFMG Informática Jr. in 2013 I worked on the development of the company\'s institutional website.'
      ],
      'imageCover': 'project_23.png',
      'imageGallery': 'g_project_23.png',
      'fid': 23,
      'images': [
        '23_1.png'
      ],
      'company': 'UFMG Informática Jr.',
      'links': {
        'site': [
          'https://www.ijunior.com.br'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.ijunior.com.br/'
      }
    },
    {
      'title': 'Scaffolder',
      'name': 'scaffolder',
      'url': 'scaffolder',
      'color': '#009c3d',
      'description': 'Design and project front-end',
      'text': [
        'What is the Scaffolder? Scaffolder is a source code generator for web systems, developed by Codificar. The main idea of ​​this project is software that develops functions that are commonly used in web systems in an automated way. ',
        'From a database modeling, the Scaffolder generates the codes referring to CRUD functions (Create, Read, Update and Delete) of each bank table, in addition to the registration, editing and listing screens. back-end was made in Laravel PHP and the front-end in AngularJs. ',
        'In the project I worked with the modeling and design of the interface models that the generator would create. I also worked on the development of the various types of UI components, such as datatables, hierarchical lists, advanced form fields such as autocomplete and uploading photos of files. '
      ],
      'date': '2017-05-07',
      'tags': [
        'system',
        'programming',
        'design',
        'logo',
        'ui design'
      ],
      'imageCover': 'project_02.png',
      'imageGallery': 'g_project_02.png',
      'fid': 2,
      'images': [
        '2_1.png',
        '2_2.png',
        '2_3.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'https://github.com/codificar/scaffolder-theme-material'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'FomeFomeApp',
      'name': 'fomefome-app',
      'url': 'fomefome-app',
      'color': '#c41459',
      'description': 'App and website icon development',
      'tags': [
        'design',
        'logo',
        'site'
      ],
      'date': '2015-11-17',
      'text': [
        'I worked as a freelancer to code development a landing page for the FomeFomeApp application, a Delivery localization application. I also developed an icon for the app with location symbols and a plate of food. '
      ],
      'imageCover': 'project_06.png',
      'imageGallery': 'g_project_06.png',
      'fid': 6,
      'images': [
        '6_4.png',
        '6_3.png',
        '6_2.png',
        '6_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'ACR Evelators',
      'name': 'site-acr-elevators',
      'url': 'site-acr-lifts',
      'color': '#4b8842',
      'description': 'Website development',
      'tags': [
        'site',
        'design',
        'ui design'
      ],
      'date': '2013-06-06',
      'text': [
        'I worked on developing the site for ACR Elevadores.'
      ],
      'imageCover': 'project_18.png',
      'imageGallery': 'g_project_18.png',
      'fid': 18,
      'images': [
        '18_1.png'
      ],
      'company': 'UFMG Informática Jr.',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.ijunior.com.br/'
      }
    },
    {
      'title': 'Android application - Cronoanalysis',
      'name': 'cronoanalise-app',
      'url': 'cronoanalise-app',
      'color': '#cc7244',
      'description': 'Android application development',
      'tags': [
        'app',
        'programming',
        'design',
        'ui design'
      ],
      'date': '2013-01-01',
      'text': [
        'In this project I worked on the development of an android application for a multi-national company. For privacy reasons no image of the application can be released. ',
        'The main functionality of the application was the time tracking of activities performed. The app was developed to operate on tablets with android system. '
      ],
      'imageCover': 'project_24.png',
      'imageGallery': 'g_project_24.png',
      'fid': 24,
      'images': [],
      'company': 'UFMG Informática Jr.',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.ijunior.com.br/'
      }
    },
    {
      'title': 'LASA UFMG',
      'name': 'site-lasa-ufmg',
      'url': 'site-lasa-ufmg',
      'color': '#172848',
      'description': 'Website development',
      'text': [
        'I worked on developing the website for LASA UFMG.'
      ],
      'date': '2013-10-05',
      'tags': [
        'design',
        'site',
        'ui design'
      ],
      'imageCover': 'project_19.png',
      'imageGallery': 'g_project_19.png',
      'fid': 19,
      'images': [
        '19_1.png'
      ],
      'company': 'UFMG Informática Jr.',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.ijunior.com.br/'
      }
    },
    {
      'title': 'Blessed Cell',
      'name': 'blessed',
      'url': 'blessed-design',
      'color': '#2f5f8e',
      'description': 'Design for shirt templates',
      'tags': [
        'design',
        'logo'
      ],
      'date': '2013-07-10',
      'text': [
        'I developed a shirt model for a group of cells. It has both female and male models. '
      ],
      'imageCover': 'project_22.png',
      'imageGallery': 'g_project_22.png',
      'fid': 22,
      'images': [
        '22_1.png',
        '22_2.png',
        '22_5.png',
        '22_6.png',
        '22_7.jpg',
        '22_8.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Viewing musical popularity',
      'name': 'musical-data',
      'url': 'dataviz-musical',
      'color': '#756bb1',
      'description': 'Information visualization development',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2014-08-01',
      'text': [
        'I developed this work as a practical work of Data Visualization. The objective of the project is to create a visualization from the Prog Archives website data. The site is a community that brings together albums and artists from the progressive rock musical genre, as well as user-contributed notes and reviews. The purpose of the visualization was to present the popularity of a musical style over time. The colors are merely illustrative. '
      ],
      'imageCover': '',
      'imageGallery': 'g_project_38.png',
      'fid': 38,
      'images': [
        '38_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Movie Media Site',
      'name': 'site-movie-media',
      'url': 'site-movie-media',
      'color': '#d20000',
      'description': 'Website development',
      'tags': [
        'site'
      ],
      'date': '2014-01-20',
      'text': [
        'I did a job as a freelancer for the Megaphone Agency for the development of the Movie Mídia website. I only worked on implementing the interfaces. '
      ],
      'imageCover': 'project_13.png',
      'imageGallery': 'g_project_13.png',
      'fid': 13,
      'images': [
        '13_1.png',
        '13_2.png',
        '13_3.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Disclosure banners',
      'name': 'banners-ijunior',
      'url': 'banners-ijunior',
      'color': '#2990bf',
      'description': 'Advertising',
      'text': [
        'I developed banners to publicize events for UFMG Informática Jr.'
      ],
      'date': '2017-05-07',
      'tags': [
        'design'
      ],
      'imageCover': 'project_09.png',
      'imageGallery': 'g_project_09.png',
      'fid': 9,
      'images': [
        '9_1.png',
        '9_2.png'
      ],
      'company': 'UFMG Informática Jr.',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.ijunior.com.br/'
      }
    },
    {
      'title': 'Center for Indian Studies - Website',
      'name': 'site-cei',
      'url': 'site-cei',
      'color': '#6fb453',
      'description': 'Website development',
      'tags': [
        'site'
      ],
      'date': '2015-01-20',
      'text': [
        'I worked on the development of the Center for Indian Studies for Cedecom UFMG. The site was made in 2015 using wordpress, when I worked at Cedecom. '
      ],
      'imageCover': 'project_31.png',
      'imageGallery': 'g_project_31.png',
      'fid': 31,
      'images': [
        '31_1.png'
      ],
      'company': 'Cedecom - Communication Center, Federal University of Minas Gerais',
      'links': {
        'site': [
          'https://www.ufmg.br/dri/cei'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'https://ufmg.br/comunicacao/sobre-o-cedecom'
      }
    },
    {
      'title': 'Luana Sistema',
      'name': 'sytem-luana',
      'url': 'sytem-luana',
      'color': '#3f5e2f',
      'description': 'System design and development',
      'tags': [
        'system',
        'programming',
        'design',
        'logo',
        'ui design'
      ],
      'date': '2015-06-20',
      'text': [
        'This was work done in a college discipline, when the proposed job was to develop a variable calculation system.',
        'My participation was the development of the project\'s front - end and the creation of the system\'s logo. Members were asked for the brand to have a chayote as its main symbol. '
      ],
      'imageCover': 'project_32.png',
      'imageGallery': 'g_project_32.png',
      'fid': 32,
      'images': [
        '32_1.png',
        '32_2.png',
        '32_3.png',
        '32_4.png',
        '32_5.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    }
  ],
};