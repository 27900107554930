import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
    font-family: Lato, 'Open Sans', 'Segoe UI', Roboto, Arial, sans-serif;
    transition: all 0.25s linear;
    line-height: 26.1px;
    overflow: auto !important;
  }
  
  li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiListItem-button > div {
    display: none !important;
  }

  a {
    color: ${({ theme }) => theme.linkColor};
  }
  `;