export const content = {
  'basic_info': {
    'name': 'Maria Luísa',
    'site': 'malu.dev',
    'full_name': 'Maria Luísa Costa Pinto',
    'resume': [
      'Mestranda',
      'Designer Gráfico',
      'Desenvolvedora Full Stack',
      'UI Designer',
      'Designer de Visualização de Dados'
    ],
    'description': 'Uma mulher negra na computação',
    'office': 'Consultora de Desenvolvimento de Software na ThoughtWorks',
    'hello': 'Olá, eu sou Maria Luísa',
    'text': ''
  },
  'intro': {
    'title': 'Maria o que?',
    'name': 'Maria Luísa Costa Pinto',
    'text': 'Eu sou Maria Luísa Costa Pinto. Atualmente trabalho como Consultora de Desenvolvimento de Software na <a href="https://www.thoughtworks.com/pt" alt="link para o site da ThoughtWorks" target="_blank">ThoughtWorks BR</a>. Sou aluna do mestrado em Ciência da Computação pela Universidade Federal de Minas Gerais, graduada em Sistemas de Informação na Universidade Federal de Minas Gerais (2016) e Técnica em Informática pela Escola Politécnica de Minas Gerais (2010). Estou há alguns anos trabalhando com tecnologia. Eu me defino como desenvolvedora front-end e UI designer.'
  },
  'profile': {
    'title': 'Perfil',
    'info': [
      {
        'subtitle': 'Pesquisa',
        'list': [
          'UI - UX Design',
          'Visualização de dados',
          'Mineração de dados para web',
          'Recuperação de Informação'
        ],
      },
      {
        'subtitle': 'Desenvolvimento',
        'list': [
          'Js, React, React Native, Jquery, AngularJs, Angular, Ionic Framework, D3js, WinJs, NodeJs',
          'HTML5, CSS3, LESS, SASS, Bootstrap, Angular Material, XML',
          'MySQL, MongoDB',
          'PHP, C, Java, Shell Script',
          'Wordpress, Joomla, Drupal',
          'Python, Jupyter Notebook'
        ],
      },
      {
        'subtitle': 'Design',
        'list': [
          'Adobe Photoshop',
          'Adobe Indesign',
          'Adobe Illustrator',
          'Adobe XD',
          'Material Design',
          'Sony Vegas (Video editor)'
        ],
      },
    ],
  },
  'academic': {
    'title': 'Formação Acadêmica',
    'list': [
      {
        'date': 'Mar 2018 - Presente',
        'place': 'Departamento de Ciência da Computação - Universidade Federal de Minas Gerais, DCC - UFMG',
        'course': 'Mestrado em Ciência da Computação'
      },
      {
        'date': 'Mar 2012 - 2016',
        'place': 'Departamento de Ciência da Computação - Universidade Federal de Minas Gerais, DCC - UFMG',
        'course': 'Bacharelado em Sistemas de Informação'
      },
      {
        'date': 'Ago 2009 - Dez 2010',
        'place': 'Escola Politécnica de Minas Gerais, Colégio Polimig',
        'course': 'Técnico em Informática'
      }
    ],
  },
  'events': {
    'title': 'Workshops, cursos complementares e eventos',
    'list': [
      {
        'date': 'Jan 2017 - Jul 2018',
        'place': 'UPTIME - Comunicação em Inglês',
        'course': 'Curso de Inglês - College',
        'description': 'Fluência em 12 meses'
      },
      {
        'date': 'Julho 2017',
        'place': 'Avalanche Missões Urbanas - Vitória, ES',
        'course': 'Comunicação e Artes',
        'description': 'Criatividade, Arte e Religião, Redação, Comunicação, Briefing e Brainstorming, Roteirização, Design Elementar, Social Media, Percepção Visual, Fotografia, Mostra de Curtas, Intervenção Urbana, História da Fotografia, Instagram e relevância social, Lightpainting, Oficina de Teatro, Expressão Corporal, História da dança, Danças Urbanas, História e Política'
      },
      {
        'date': 'Mar 2013 - Jul 2014',
        'place': 'Bolsa de Iniciação Científica do CNPQ',
        'course': 'Programa de Iniciação Científica e Mestrado em Matemática, PICME'
      },
      {
        'date': 'Jul 2008 - Dez de 2009',
        'place': 'Bolsa de Iniciação Científica Jr. do CNPQ',
        'description': 'Olimpíada Brasileira de Matemática das Escolas Públicas , OBMEP',
        'course': 'Programa de Iniciação Científica da OBMEP'
      },
      {
        'date': 'Dez 2016',
        'course': 'Marketing de Conteúdo',
        'place': 'Ministrantes: Rock Content',
        'description': 'Carga horária: 10hrs'
      },
      {
        'date': 'Ago 2013 - Dez 2014',
        'course': 'Curso de Inglês - Nível Básico',
        'place': 'Cacs - Projeto de Ensino de Línguas Estrangeiras',
        'description': ''
      },
      {
        'date': 'jan 2014',
        'course': 'CoW - HTML5|CSS3|Javascript',
        'place': 'Ministrantes: Bernard de luna e Zeno Rocha',
        'description': 'Carga horária: 12hrs'
      },
      {
        'date': 'Dez 2013',
        'course': 'MEAN Stack - MongoDB, ExpressJS, AngularJS, NodeJS',
        'place': 'Ministrante: Suissa',
        'description': 'Carga horária: 20hrs'
      },
      {
        'date': 'Nov 2013',
        'course': 'Treinamento de DRUPAL',
        'place': 'Ministrante: Fisqua',
        'description': 'Carga horária: 120hrs'
      },
      {
        'date': 'Out 2013 - Nov 2013',
        'course': 'PHP Avançado',
        'place': 'Ministrante: Igor Rafael',
        'description': 'Carga horária: 36 hrs'
      },
      {
        'date': 'Ago 2013',
        'course': 'Front in BH',
        'place': '',
        'description': ''
      },
      {
        'date': 'Fev 2013',
        'course': 'Bootcamp Android',
        'place': 'Ministrante: Leonardo Barros (GDG - Belo Horizonte)',
        'description': ''
      }
    ],
  },
  'awards': {
    'title': 'Premiações e curiosidades',
    'list': [
      {
        'date': 'Mar 2019',
        'competition': 'Entrevista para os canais de comunicação da UFMG',
        'award': 'Vídeo sobre minha trajetória profissional e acadêmica, disponível em todos os canais oficiais da Universidade.',
        'description': 'Link no youtube: <a href="https://www.youtube.com/watch?v=HLuY2-Z4EXE&t=1s" target="_blank">clique aqui para assistir</a>.'
      },
      {
        'date': 'Fev 2019',
        'competition': 'The Big Hackathon - Campus Party Brasil 12',
        'award': '3º lugar na Final e na primeira fase 1º lugar nas categorias Direitos Humanos e Finanças',
        'description': 'Participei de um Hackathon na Campus Party BR 12 em São Paulo onde tive a honra de conseguir uma premiação.'
      },
      {
        'date': 'Dez 2018',
        'competition': 'Palestra sobre Visualização de Dados',
        'award': 'Palestrante',
        'description': 'Contribui no evento Minutrends Day com uma palestra sobre Visualização de Dados.'
      },
      {
        'date': 'Jul 2018',
        'competition': 'Concurso para criação de Selo comemorativo - 50 anos ICEx UFMG',
        'award': 'Desenvolveu o logotipo vencedor da competição',
        'description': 'Participação de concurso de logos, onde tive a honra de ser a vencedora.'
      },
      {
        'date': 'Dez 2013',
        'competition': 'Diretora de Marketing e Vendas',
        'award': 'Premiação pela boa diretoria',
        'description': 'Reconhecimento da UFMG Informática Jr pela boa gestão na diretoria de Marketing.'
      },
      {
        'date': 'Out 2010',
        'competition': 'Feira tecnológica',
        'award': '4º Lugar na FEIRA DE TECNOLOGIA E CIÊNCIAS POLIVITAL',
        'description': 'Desenvolvimento de um jogo em VB6, interativo de perguntas e respostas previamente inseridas em um banco de dados externo.'
      },
      {
        'date': 'OBMEP 2009',
        'competition': '5ª Olimpíada Brasileira de Matemática das Escolas Públicas, OBMEP',
        'award': 'Menção Honrosa',
        'description': ''
      },
      {
        'date': 'OBMEP 2008',
        'competition': '4ª Olimpíada Brasileira de Matemática das Escolas Públicas, OBMEP',
        'award': 'Menção Honrosa',
        'description': ''
      },
      {
        'date': 'OBMEP 2007',
        'competition': '3ª Olimpíada Brasileira de Matemática das Escolas Públicas, OBMEP',
        'award': 'Menção Honrosa',
        'description': ''
      }
    ]
  },
  'professional': {
    'title': 'Experiência Profissional',
    'list': [
      {
        'date': 'Jan 2020 - Presente',
        'place': 'ThoughtWorks',
        'office': 'Consultant Developer',
        'knowledge': 'React, React Native',
      },
      {
        'date': 'Jul 2018 - Dez 2019',
        'place': 'Minutrade',
        'office': 'UI Developer',
        'knowledge': 'UI Design, Javascript, React, AngularJs, Node, MongoDB, Adobe Xd, Adobe Photoshop'
      },
      {
        'date': 'Jul 2015 - Jun 2018',
        'place': 'Codificar Sistemas Tecnológicos',
        'office': 'UI/UX designer, developer',
        'knowledge': 'Javascript, React Native, Angular Js, Angular 4, Node Js, Gulp, LESS|SASS, Ionic Framework, UI Design para App Andoid e iOS, UI Design para Sistemas Web, Angular Material e Material Design, Responsive Layout, Front-end XML para Android e Objective C para iOS.'
      },
      {
        'date': 'Jun 2015 - Jul 2015',
        'place': 'Paiva Piovesan Softwares',
        'office': 'Front-end Developer',
        'knowledge': 'AngularJs - Kendo UI Framework - c#'
      },
      {
        'date': 'Jan 2014 - Presente',
        'place': 'Freelancer',
        'office': 'Web Designer, Designer Gráfico, programação web',
        'knowledge': ''
      },
      {
        'date': 'Ago 2013 - Jun 2015',
        'place': 'Centro de Comunicação da UFMG',
        'office': 'Web Developer, Trainee',
        'knowledge': 'JavaScript, Node Js - MongoDB - Angular Js, PHP, Wordpress, CMS Drupal, HTML5|CSS3, LESS|SASS, Grunt|Gulp'
      },
      {
        'date': 'Mar 2012 - Dez 2013',
        'place': 'UFMG Informática Jr.',
        'office': 'Diretora de Marketing e Vendas, Gestão de Pessoas, Scrum Developer',
        'knowledge': 'Elaboração de propostas de negociação, Levantamento de requisitos de softwares. Desenvolvimento de campanhas de Marketing. Gestão de pessoas e de projetos. Experiência técnica em: HTML, CSS, JavaScript, Bootstrap, PHP, Mysql, Joomla, Java para Android, Scrum, Git.'
      },
      {
        'date': 'Ago 2011 - Nov 2011',
        'place': 'Prefeitura Municipal de Sabará em parceria com o SINE-Sabará',
        'office': 'Técnica em Informática',
        'knowledge': 'Manutenção de computadores. Suporte e treinamento de equipe.'
      }
    ]
  },
  'menu_header': {
    'about': 'About me',
    'portfolio': 'Portfolio'
  },
  'buttons': {
    'portfolio': 'Portfolio',
    'profile': 'Perfil',
    'dark_theme': 'Escuro',
    'light_theme': 'Claro',
  },
  'contact': {
    'title': 'Contato'
  },
  'footer': {
    'place': 'Belo Horizonte, Brasil',
    'contact': 'Entre em contato comigo',
    'cv': 'Download CV',
    'social_networks': 'Redes sociais',
    'btn_download_pt': 'Português',
    'btn_download_en': 'Inglês',
    'sentence': '"Só sei que nada sei" - Sócrates',
    'directs': '© 2020 Maria Luísa Costa Pinto. Todos os direitos reservados.',
  },
  'actions': {
    'back': 'Voltar'
  },
  'messages': {
    'job_type_free': 'Desenvolvi este projeto como ',
    'job_type_company': 'Desenvolvi este projeto pela empresa ',
    'access_site': 'Clique aqui para acessar o site do projeto',
    'access_behance': 'Clique aqui para ver este projeto no Behance',
    'access_code': 'Clique aqui para ver o código fonte do projeto',
  },
  'alt': {
    'project_link_site': 'Link do site do projeto',
    'project_link_code': 'Link do código fonte do projeto',
  }
};