import styled from 'styled-components';

import { Link } from "react-router-dom";
import { Content } from '../../styles/common';

export const ContentProjects = styled(Content)`
  &, & * {
    text-decoration: none;
    color: ${(props) => props.theme.text}};
  }  
`;
