import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Section = styled.div`
  text-align: center;
  min-height: 490px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-bottom: 0px;
`;

export const Content = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  text-align: center;
  padding-top: 64px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  min-height: 320px;

  @media (max-width: 1120px) {
    padding: 40px;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

export const ContentIntro = styled.div``;

export const Avatar = styled.div`
  background-image: url(./images/perfil.jpg);
  background-size: 100%;
  height: 184px;
  width: 184px;
  border-radius: 50%;
  margin: 0 auto;

  @media (max-width: 280px) {
    height: 100px;
    width: 100px;
  }
`;

export const ContentButtons = styled.div``;

export const LinkContent = styled(Link)`
  text-decoration: none;
  outline: none;
`;

export const TextContent = styled.div``;



export const TextIntro = styled.p`
  text-align: justify;

  @media (max-width: 480px) {
    text-align: left;
  }
`;


