import React from 'react';
import {
  Section, Avatar, Content, TextContent,
  ContentButtons, ContentIntro,
  LinkContent, TextIntro
} from './Intro.styles';
import Button from '../button/Button';
import ReactHtmlParser from 'react-html-parser';

const Intro = ({ t, showProfile }) => {
  const showButtons = false;
  return (
    <Section>
      <Content>
        <Avatar></Avatar>

        <TextContent>
          <ContentIntro>
            <h1> {t('basic_info.name')} </h1>
            <TextIntro> {ReactHtmlParser(t('intro.text'))} </TextIntro>
          </ContentIntro>
          {showButtons && <ContentButtons>
            <LinkContent to={{ pathname: '/' }} onClick={() => showProfile(true)}>
              <Button t={t} idText={'buttons.profile'} />
            </LinkContent>
            <LinkContent to={{ pathname: '/projects' }}>
              <Button t={t} idText={'buttons.portfolio'} />
            </LinkContent>
          </ContentButtons>}
        </TextContent>
      </Content>
    </Section>
  )
};

export default Intro;
