import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { YearProject, Description, Subtitle, JobType } from './ProjectDescription.styles';

const getYearComponent = (year, project) => {
  return year ?
    <span> - <YearProject color={project.color}> ({year}) </YearProject></span> : ''
};

const getCompanyDescription = (t, company) => {
  const freeMessage = t('messages.job_type_free');
  const companyMessage = t('messages.job_type_company');

  if (company.toLowerCase() === 'freelancer' || company.toLowerCase() === 'student') {
    return freeMessage + company;
  }
  return companyMessage + company;
};

const ProjectDescription = ({ description, index, t, year, project }) => (
  <Description color={project.color}>
    <Subtitle>
      {ReactHtmlParser(t(`projects.list.${index}.description`))} {getYearComponent(year, project)}
    </Subtitle>
    {project.company && <JobType>{getCompanyDescription(t, project.company)}</JobType> || ''}

    {
      description && description.map((paragraph, tIndex) => {
        return (
          <div key={tIndex}>{
            ReactHtmlParser(t(`projects.list.${index}.text.${tIndex}`))
          }</div>
        )
      })
    }
  </Description>
);

export default ProjectDescription;