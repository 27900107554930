import styled from 'styled-components';

export const Links = styled.div`
  padding: 24px 0;

  @media(max-width: 960px) {
    display: block;
  }
`;

export const Link = styled.a`
  border: solid 1px ${({ theme }) => theme.transparentLine};
  padding: 8px 32px;
  border-radius: 32px;
  margin: 32px 4px;
  text-decoration: none;
  color: ${({ theme }) => theme.grayColor};
  cursor: pointer;

  @media(max-width: 960px) {
    display: block;
  }
`;

export const MobileButton = styled(Link)`
  background-image: url(../images/${(props) => props.icon}_${({ theme }) => theme.value}.png);
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  width: 160px;
  height: 54px;
  display: flex;
  padding: 0 42px;
`;

export const MobileLinks = styled.div`
  display: flex;

  @media(max-width: 960px) {
    display: block;
  }
`;