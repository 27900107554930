import React from 'react';

import { SOCIAL_NETWORKS } from '../../utils/Links';

import {
  Footer, Area, ContentFooter, ContentInfoFooter,
  LinkSocialNetwork, AreaLinks,
  IconSocialNetwork, CopyRight
} from './Footer.styles';

export default ({ t }) => (
  <Footer>
    <ContentFooter>
      <ContentInfoFooter>
        <Area>
          <h4> {t('footer.contact')} </h4>
          <ul>
            <li>contato@malu.dev</li>
            <li>{t('footer.place')}</li>
          </ul>
        </Area>
        <Area>
          <h4> {t('footer.social_networks')} </h4>
          <AreaLinks>
            {SOCIAL_NETWORKS && SOCIAL_NETWORKS.map((item, idx) => (
              (item.status) && <LinkSocialNetwork
                key={idx}
                target='_blank'
                href={item.link}
                alt={`Link ${item.name}`}
              >
                <IconSocialNetwork icon={`icon-${item.name.toLowerCase()}`} />
              </LinkSocialNetwork>
            ))}
          </AreaLinks>
        </Area>
      </ContentInfoFooter>
      <CopyRight> {t('footer.directs')} </CopyRight>
    </ContentFooter>
  </Footer>
);