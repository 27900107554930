export const colorsPallete = [
  '#ff6699',
  '#22A7F0'
];

export const lightTheme = {
  value: 'light',
  body: '#ffffff',
  text: '#121212',
  transparentLine: '#ccc',
  toggleBorder: '#FFF',
  linkColor: colorsPallete[0],
  gradient: 'linear-gradient(#39598A, #79D7ED)',
  primaryColor: colorsPallete[0],
  secondColor: colorsPallete[1],
  grayColor: '#555',
  currentColor: colorsPallete[0],
};

export const darkTheme = {
  value: 'dark',
  body: '#121212',
  text: '#ffffff',
  transparentLine: '#333',
  toggleBorder: '#6B8096',
  linkColor: colorsPallete[0],
  gradient: 'linear-gradient(#091236, #1E215D)',
  primaryColor: colorsPallete[0],
  secondColor: colorsPallete[1],
  grayColor: '#ccc',
  currentColor: colorsPallete[0],
};

export const theme = {
  primary: '#004790',
  secondary: '#0287D0',
};