import styled from 'styled-components';

export const BackButton = styled.button`
  outline: none;
  border: none;
  color: ${({ theme }) => theme.grayColor};
  border: solid 1px ${({ theme }) => theme.grayColor};
  background: transparent;
  padding: 16px;
  margin-bottom: 24px;
`;

export const ImageCover = styled.div`
  background-image: url(../images/projects/${(props) => props.image});
  height: 420px;
  width: 100%;

  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 80%;
  margin-bottom: 64px;

  @media(max-width: 1780px) {
    height: 360px;
  }

  @media(max-width: 1680px) {
    height: 300px;
  }

  @media(max-width: 1490px) {
    background-size: 100%;
    height: 220px;
  }

  @media(max-width: 960px) {
    height: 120px;
  }
`;

export const ImageProject = styled.img`
  display: block;
  width: 100%;
  margin: 24px auto;
`;