import styled from 'styled-components';

import { Link } from "react-router-dom";
import { Content } from '../../styles/common';

export const Project = styled.div`
  display: inline-flex;
  width: 320px;
  margin: 16px;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);

  @media(max-width: 320px) {
    width: 100%;
    margin: 0;
    display: block;
    overflow: hidden;
  }
`;

export const ProjectLink = styled(Link)`
  display: block;
`;

export const ContentProject = styled.div`
  background: rgb(0, 0, 0, .85);
  align-items: flex-end;
  display: flex;
  vertical-align: bottom;
  position: absolute;
  width: 320px;
  height: 320px;
  margin-top: -320px;
  padding: 0px 16px;
  opacity: 0;
  
  &:hover {
    opacity: 1;
  }
`;

export const TitleProject = styled.h3`
  height: 72px;
  font-size: 14px;
  background-color: ${({ theme, color }) => color || theme.primaryColor};
  color: #fff !important;
  padding: 8px;
  margin: 0px;
`;

export const DescriptionProject = styled.p`
  font-size: 16px;
  color: #fff !important;
  line-height: 1.3em;
  font-weight: bold;
  text-align: center;
`;

export const CoverProject = styled.div`
  background-image: url(../images/projects/${(props) => props.url});
  background-size: auto 100%;
  background-position: center;
  height: 320px;
  width: 320px;
  overflow: hidden;
`;
