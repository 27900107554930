export const model = {
    "title": '',
    "description": '',
    "list": [
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "imageCover": '',
            "fid": 59,
            "imageGallery": '',
            "images": [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                '',
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "imageCover": '',
            "fid": 58,
            "imageGallery": '',
            "images": [
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                '',
                '',
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "imageCover": '',
            "fid": 1,
            "imageGallery": '',
            "images": [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 14,
            "images": [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 34,
            "images": [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 55,
            "images": [
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 40,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 39,
            "images": [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 57,
            "images": [
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 48,
            "images": [
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 46,
            "images": [
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 56,
            "images": [
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 41,
            "images": [
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 47,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 44,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 42,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 37,
            "images": [
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 49,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 33,
            "images": [
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                ''
            ],
            "date": '',
            "text": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 52,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 27,
            "images": [
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 43,
            "images": [
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 54,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 30,
            "images": [
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 10,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 51,
            "images": [
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                '',
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 3,
            "images": [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 15,
            "images": [
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    '',
                    '',
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                '',
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 4,
            "images": [
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                ''
            ],
            "date": '',
            "tags": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 45,
            "images": [
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 5,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 21,
            "images": [
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 28,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 29,
            "images": [
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 53,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 7,
            "images": [
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 50,
            "images": [],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 25,
            "images": [
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 12,
            "images": [
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 11,
            "images": [
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 8,
            "images": [
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 17,
            "images": [
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 16,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
                "code": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 26,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 20,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 23,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                '',
                '',
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 2,
            "images": [
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 6,
            "images": [
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 18,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 24,
            "images": [],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                ''
            ],
            "date": '',
            "tags": [
                '',
                '',
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 19,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 22,
            "images": [
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": ''
            }
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                ''
            ],
            "date": '',
            "text": [
                '',
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 38,
            "images": [
                '',
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
            },
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                ''
            ],
            "date": '',
            "text": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 13,
            "images": [
                '',
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
            },
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "text": [
                ''
            ],
            "date": '',
            "tags": [
                ''
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 9,
            "images": [
                '',
                ''
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
            },
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
            ],
            "date": '',
            "text": [
                '',
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 31,
            "images": [
                ''
            ],
            "company": '',
            "links": {
                "site": [
                    ''
                ],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
            },
        },
        {
            "title": '',
            "name": '',
            "url": '',
            "description": '',
            "tags": [
                '',
                '',
                '',
                '',
                '',
            ],
            "date": '',
            "text": [
                '',
                '',
            ],
            "imageCover": '',
            "imageGallery": '',
            "fid": 32,
            "images": [
                '',
                '',
                '',
                '',
                '',
            ],
            "company": '',
            "links": {
                "site": [],
                "bhc": '',
                "app_and": '',
                "app_ios": '',
                "comp": '',
            },
        },
    ],
};