import styled from 'styled-components';

export const YearProject = styled.span`
  color: ${({ theme, color }) => color || theme.grayColor};
`;

export const Description = styled.div`
  & a {
    color: ${({ theme, color }) => color || theme.grayColor};
  }
`;

export const Subtitle = styled.p`
  border-bottom: solid 1px ${({ theme }) => theme.transparentLine};
  width: 75%;
  padding-bottom: 16px;
  margin-botttom: 8px;
`;

export const JobType = styled.p`
  font-style: italic;
  color: ${({ theme }) => theme.grayColor};
`;
