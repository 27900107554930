import styled from 'styled-components';

export const ButtonContent = styled.button`
  border: solid 2px ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-size: 16px;
  padding: 16px;
  display: inline-flex;
  background: transparent;
  margin: 8px;
  outline: none;
`;

