import React from 'react';
import { Links, Link, MobileButton, MobileLinks } from './ProjectLinks.styles';

export const getValidUrl = (listLinks) => {
  let urls = [];
  const arrayLinks = listLinks && Object.entries(listLinks);
  arrayLinks.map(link => {
    const url = link[1];
    if (typeof url === 'string' && url) {
      urls.push(link);
    }
    if (typeof url === 'object' && url.length) {
      link[1] = url[0];
      urls.push(link);
    }
  });

  const objLinks = {};
  urls.map(url => {
    objLinks[url[0]] = url[1]
  });
  return objLinks;
};

const linkButton = (t, url, alt, message) => (
  <Link href={url} alt={alt} target='_blank'>
    {message}
  </Link>
);

const mobileButton = (t, url, icon, type) => (
  <MobileButton icon={icon} href={url} alt={`Link ${type}`} target='_blank' />
);

export default ({ project, t }) => {
  const urls = getValidUrl(project.links);

  const componentLinks = <Links>
    {urls && urls.site && linkButton(t, urls.site, t('alt.project_link_site'), t('messages.access_site'))}
    {urls && urls.bhc && linkButton(t, urls.bhc, 'Behance url', t('messages.access_behance'))}
    {urls && urls.code && linkButton(t, urls.code, t('alt.project_link_code'), t('messages.access_code'))}

    <MobileLinks>
      {urls && urls.app_and && mobileButton(t, urls.app_and, 'icon_google_play', 'Google Play')}
      {urls && urls.app_ios && mobileButton(t, urls.app_ios, 'icon_app_store', 'App Store')}
    </MobileLinks>
  </Links>;

  return componentLinks;
};
