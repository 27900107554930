import styled from 'styled-components';

export const Content = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 80px 0;

  @media(max-width: 1140px) {
    padding: 64px;
  }

  @media(max-width: 540px) {
    width: 100%;
    padding: 32px;
  }
`;