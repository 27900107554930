export const projects = {
  'title': 'Conheça um pouco do meu trabalho',
  'description': 'Oi, aqui você pode conhecer um pouco mais do meu trabalho. Alguns dos projetos que trabalhei não podem ser exibidos neste portfólio por uma questão de privacidade e sigilo de informações. No entanto separei uma lista de alguns trabalhos, tanto como freelancer ou como membro de alguma empresa que resumem bem o trabalho que desenvolvo. Você pode filtrar por categorias de projetos:',
  'list': [
    {
      'title': 'Aplicativo de anotações - Estudo de UI/UX',
      'name': 'notes-app-ui',
      'url': 'notes-app-ui',
      'color': '#ff693b',
      'description': 'Projeto simples de aplicativo de notas',
      'text': [
        'Este aplicativo é um aplicativo móvel simples de notas. Os usuários podem escrever notas curtas, como números de telefone, links, tarefas de rotina e outras coisas. Desenhei algumas ilustrações para o aplicativo para dar um clima criativo e legal. As cores foram pensadas como post its. Aqui você pode ver mais detalhes sobre a concepção e criação da projeção. Fiz este projeto como um projeto de estudo em UI/UX design, mas estou desenvolvendo este aplicativo para publicar em lojas de aplicativos. Abaixo, você pode ver as tecnologias de projeto utilizadas:',
        'Design e desenvolvimento',
        '<ul> <li>Adobe XD</li> <li>Adobe Illustration</li> <li>Photoshop</li><li>Icons: react-native-fontawesome</li><li>Images: drew by me</li><li>React Native</li> <li>React Navigation</li> <li>React Hooks</li> <li>Components: custom React Native components</li></ul>'
      ],
      'date': '2021-04-04',
      'tags': [
        'programming',
        'app',
        'ui design',
        'design',
      ],
      'imageCover': 'project_59.png',
      'fid': 59,
      'imageGallery': 'g_project_59.png',
      'images': [
        '59_1.jpg',
        '59_2.jpg',
        '59_3.jpg',
        '59_4.jpg',
        '59_5.jpg',
        '59_6.jpg',
        '59_7.jpg',
        '59_8.jpg',
        '59_9.jpg',
        '59_10.jpg',
      ],
      'company': 'Student',
      'links': {
        'site': [],
        'bhc': 'https://www.behance.net/gallery/116834655/Notes-App',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/react-native-ui-animations'
      }
    },
    {
      'title': 'Escritótio de advocacia Lislene Costa - App',
      'name': 'lislene-costa-advocacia',
      'url': 'law-office-lislene-costa',
      'color': '#8c0403',
      'description': 'Uma aplicação web e aplicativo Android para um escritório de advocacia',
      'text': [
        'Neste projeto desenvolvi um serviço de gestão de tarefas para um escritório de advocacia da Advogada Lislene Costa.',
        'Foi criada uma aplicação web que gerencia os serviços realizados pelo escritório e um aplicativo mobile Android que recebe notificações de alertas de vencimento os prazos. Além disso também desenvolvi o logo do escritório, pois estava precisando.',
        'Em resumo, este foi um produto completo quando se fala de tecnologia e inserção digital: serviço online, criação gráfica e aplicativos para dispositivos móveis',
        'As tecnologias usadas no desenvolvimento foram: <ul><li> React </li><li> React Native </li><li>API para login com a conta Google</li><li>Serviço de disparo de notificações pelo Firebase </li><li> Firebase Hosting </li> <li> Node.js APIs </li> <li> Uitlização da API Google Sheets para consumir alguns dados de planilhas</li> <li> MongoDB </li> <li> Material Design </li> </ul>'
      ],
      'date': '2020-02-20',
      'tags': [
        'sistema',
        'programming',
        'app',
        'ui design',
        'design',
        'logo'
      ],
      'imageCover': 'project_58.png',
      'fid': 58,
      'imageGallery': 'g_project_58.png',
      'images': [
        '58_1.jpg',
        '58_2.jpg',
        '58_3.jpg',
        '58_4.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Biblexplorer',
      'name': 'biblexplorer',
      'url': 'biblexplorer',
      'color': '#008eba',
      'description': 'Exploração de dados da Bíblia',
      'text': [
        'Biblexplorer é um projeto de pesquisa e desenvolvimento tecnológico. Ele tem sido meu principal instrumento de pesquisa focada nas áreas da computação: Visualização de Informação, Recuperação de Informação, Processamento de Linguagem Natural e UX Design.',
        'Este trabalho nasceu na universidade como um projeto de visualizações de informação da Bíblia e a medida que o projeto foi sendo desenvolvido, novas funcionalidades foram sendo acrescentadas.',
        'No cenário de Visualização de dados, o trabalho comtempla o desenvolvimento de técnicas para visualizar textos e informações sobre a Bíblia através de gráficos, mapas e outras. Todos os recursos em visualização foram feitos usando a biblioteca D3Js.',
        'Em Processamento de linguagem Natural a pesquisa assume desafios utilizando métodos do estado-da-arte como a utilização de Word Embeddings para o rankeamento de um SRI Sistema de Recuperação de Informação. A ideia principal nestas áreas é desenvolver uma máquina de busca que compreenda conceitos e contextos bíblicos.',
        'O trabalho consiste em um conjunto de aplicações. As tecnologias usadas para desenvolver este trabalho foram: AngularJs, D3Js, MongoDB, Spring Framework, PHP, Ionic Framework, Apache Lucene, DeepLearning4j, Shell Script.'
      ],
      'date': '2017-05-07',
      'tags': [
        'sistema',
        'programming',
        'site',
        'dataviz',
        'ui design',
        'design'
      ],
      'imageCover': 'project_01.png',
      'fid': 1,
      'imageGallery': 'g_project_01.png',
      'images': [
        '1_11.png',
        '1_12.png',
        '1_1.png',
        '1_2.png',
        '1_3.png',
        '1_4.png',
        '1_5.png',
        '1_6.png',
        '1_7.png',
        '1_10.png',
        '1_8.png',
        '1_9.png',
        '1_13.png',
        '1_14.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://v1.biblexplorer.com.br/'
        ],
        'bhc': '',
        'app_and': 'https://play.google.com/store/apps/details?id=com.br.biblexplorer',
        'app_ios': 'https://itunes.apple.com/us/app/biblexplorer/id1144180913',
        'comp': ''
      }
    },
    {
      'title': 'United Statisticians',
      'name': 'united-statisticians',
      'url': 'united-statisticians',
      'color': '#f8a945',
      'description': 'UI Design de sistema',
      'text': [
        'Desenvolvi o protótipo das interfaces do portal web United Statisticians. Foi um trabalho de UI Design de todas as telas do sistema.',
        'United Statisticians é uma plataforma online de consultores de estatística. Para estudantes, pesquisadores e empresas buscando suporte estatístico (análise de dados, provas de faculdade, pesquisa de mercado, etc.), a United Statisticians oferece uma plataforma online que ajuda clientes a encontrar consultores de estatística, empresas de consultoria estatística e cientistas de dados em poucos cliques. Também desenhei alguns banners, persosnagens e material de divulgação.'
      ],
      'date': '2017-05-07',
      'tags': [
        'design',
        'sistema',
        'programming',
        'ilustração',
        'dataviz',
        'ui design'
      ],
      'imageCover': 'project_14.png',
      'imageGallery': 'g_project_14.png',
      'fid': 14,
      'images': [
        '14_1.png',
        '14_3.png',
        '14_2.png',
        '14_4.png',
        '14_5.png',
        '14_6.png',
        '14_7.png',
        '14_8.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'https://unitedstatisticians.com'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Selo comemorativo - 50 anos ICEx UFMG',
      'name': 'icex-ufmg-50-anos',
      'url': 'icex-ufmg-50-years',
      'color': '#9a1818',
      'description': 'Design de logo',
      'tags': [
        'design',
        'logo',
        'ilustração'
      ],
      'date': '2018-06-01',
      'text': [
        'Ao completar 50 anos, o <a href="http://www.icex.ufmg.br" target="_blank">Instituto de Ciência Exatas da UFMG</a> abriu um concurso para criação de um logotivo em comemoração desta data. Participei e tive a honra de ser a vencedora, com o trabalho que pode ser visto abaixo.',
        'Neste projeto o conceito presente no selo de 50 anos do Instituto de Ciências Exatas combina o formato atual da marca do instituto com elementos geométricos, que estão presentes em todo o prédio e nas disciplinas dos cursos oferecidos pelo ICEx.'
      ],
      'imageCover': 'project_34.png',
      'imageGallery': 'g_project_34.png',
      'fid': 34,
      'images': [
        '34_1.png',
        '34_2.png',
        '34_3.png',
        '34_4.png',
        '34_5.png',
        '34_6.png',
        '34_7.png',
        '34_8.png',
        '34_9.png',
        '34_10.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://www.icex.ufmg.br/'
        ],
        'bhc': 'https://www.behance.net/gallery/67763503/ICEx-UFMG-50-anos',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Biblexplorer Aplicativo',
      'name': 'biblexplorer-app',
      'url': 'biblexplorer-app',
      'color': '#008eba',
      'description': 'Aplicativo para Android e iOS',
      'tags': [
        'app',
        'programming',
        'ui design'
      ],
      'date': '2017-07-30',
      'text': [
        'Aplicativo da Bíblia, desenvolvido por mim utilizando Ionic Framework, publicado na Google Play e na App Store.'
      ],
      'imageCover': 'project_55.png',
      'imageGallery': 'g_project_55.png',
      'fid': 55,
      'images': [
        '55_1.jpg',
        '55_2.jpg',
        '55_3.jpg',
        '55_4.jpg',
        '55_5.jpg',
        '55_6.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://v1.biblexplorer.com.br/'
        ],
        'bhc': 'https://www.behance.net/gallery/85185851/Biblexplorer-aplicativo',
        'app_and': 'https://play.google.com/store/apps/details?id=com.br.biblexplorer',
        'app_ios': 'https://itunes.apple.com/us/app/biblexplorer/id1144180913',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'Sisu UFMG - Visualização de dados',
      'name': 'dataviz-sisu',
      'url': 'dataviz-sisu',
      'color': '#3d6b72',
      'description': 'Visualização de dados do Sisu UFMG 2018',
      'tags': [
        'dataviz'
      ],
      'date': '2018-08-01',
      'text': [
        'Fiz um pequeno projeto de Visualização de dados com as informações do Sisu UFMG 2018.'
      ],
      'imageCover': 'project_40.png',
      'imageGallery': 'g_project_40.png',
      'fid': 40,
      'images': [
        '40_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://homepages.dcc.ufmg.br/~maria.luisa/vis/sisu/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/sisu-data'
      }
    },
    {
      'title': 'BitGirls',
      'name': 'bitgirls',
      'url': 'bitgirls',
      'color': '#ce7477',
      'description': 'Design de logotipo',
      'tags': [
        'design',
        'logo'
      ],
      'date': '2018-07-16',
      'text': [
        'Trabalhei na criação da marca BitGirls. BitGirls é uma iniciativa do Departamento de Ciência da computação para incentivar meninas a entrar nos cursos de Computação.'
      ],
      'imageCover': 'project_39.png',
      'imageGallery': 'g_project_39.png',
      'fid': 39,
      'images': [
        '39_1.png',
        '39_2.png',
        '39_3.png',
        '39_4.png',
        '39_5.png',
        '39_6.png',
        '39_7.png',
        '39_8.png',
        '39_9.png',
        '39_10.png',
        '39_11.png',
        '39_12.png',
        '39_13.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://www.bitgirls.dcc.ufmg.br/'
        ],
        'bhc': 'https://www.behance.net/gallery/71344311/Bit-Girls',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Materiais de divulgação para empresa Trixel',
      'name': 'trixel-folder',
      'url': 'trixel-folder',
      'color': '#004865',
      'description': 'Projeto feito para empresa Trixel. Foram vários material de divulgação, segue abaixo o modelo do folder desenvolvido.',
      'tags': [
        'design'
      ],
      'date': '2019-11-05',
      'text': [
        'Realizei como freelancer um projeto que consistiu no desenvolvimento de alguns materiais de divulgação para a empresa Trixel.'
      ],
      'imageCover': 'project_57.png',
      'imageGallery': 'g_project_57.png',
      'fid': 57,
      'images': [
        '57_1.jpg',
        '57_2.jpg',
        '57_3.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'Visualização - Salário Mínimo no Brasil',
      'name': 'minimum-wages-brazil',
      'url': 'minimum-wages-brazil',
      'color': '#4971be',
      'description': 'Visualização interativa desenvolvida com dados do IBGE',
      'tags': [
        'dataviz',
        'programming',
        'ui design'
      ],
      'date': '2019-02-20',
      'text': [
        'Estou desenvolvendo um pequeno experimento com dados do salário mínimo no Brasil. O objetivo é colocar em prática algumas boas práticas de programação no desenvolvimento de visualização de dados com D3Js.',
        'Este projeto, embora simples, aplica algumas práticas em D3, desenvolvidas do zero:',
        '<ul> <li>Gráfico de linha simples com pontos;</li> <li>Grid de linhas ao fundo do gráfico;</li> <li>Hover nos pontos;</li> <li>Descrição em alguns itens como default;</li> <li>ES6+ Js;</li> <li>Modularização de funções;</li></ul>'
      ],
      'imageCover': 'project_48.png',
      'imageGallery': 'g_project_48.png',
      'fid': 48,
      'images': [
        '48_1.png',
        '48_2.png',
        '48_3.gif'
      ],
      'company': 'Student',
      'links': {
        'site': [
          'https://marialuisacp.github.io/minimum-wages-brazil/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/minimum-wages-brazil'
      }
    },
    {
      'title': 'Agente Sofia',
      'name': 'sofia-social-worker',
      'url': 'sofia-social-worker',
      'color': '#2e7d7c',
      'description': 'Aplicação desenvolvida no The Big Hackathon na Campus Party Brasil 12 em São Paulo.',
      'tags': [
        'design',
        'ilustração',
        'logo',
        'programming',
        'sistema',
        'site',
        'ui design',
        'dataviz'
      ],
      'date': '2019-02-12',
      'text': [
        'Projeto para o The Big Hackathon na Campus Party Brasil 12 com o qual conseguimos o 1º lugar na categoria Direitos Humanos e o 3º lugar na final. No hackathon foram apresentados seis temas e as equipes tinham que apresentar soluções para um ou mais dentre os temas propostos.',
        'Neste trabalho nós criamos uma solução para uma questão relacionada a Direitos Humanos, envolvendo crianças em situação de risco.',
        'Fizemos uma assistente social virtual chamada Sofia. Ela conversa com as pessoas por um chatbot automático e coleta informações referentes a crianças em situação de vulnerabilidade. O chat foi feito pelo Blip. Além disso desenvolvemos uma aplicação web que exibe os dados enviados pelo chat em um mapa. Este projeto foi desenvolvido com <a href="https://github.com/hugoiuri" target="_blank">Hugo Iuri</a>, <a href="https://github.com/kpazfagundes" target="_blank">Karine Paz Fagundes</a> e <a href="https://www.linkedin.com/in/estela-kanashiki-1714073a/" target="_blank">Estela Kanashiki</a> em fevereiro de 2019 na CPBR12 em São Paulo.'
      ],
      'imageCover': 'project_46.png',
      'imageGallery': 'g_project_46.png',
      'fid': 46,
      'images': [
        '46_1.PNG',
        '46_2.png',
        '46_3.PNG'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://agentesofia-a9775.firebaseapp.com'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/agente-sofia-project-web/'
      }
    },
    {
      'title': 'E-commerce UI/UX Estudo',
      'name': 'e-commerce-ui-ux',
      'url': 'e-commerce-ui-ux',
      'color': '#0068ad',
      'description': 'Projeto feito para estudo e prática de UI/UX de sistema de vendas.',
      'tags': [
        'design',
        'site',
        'ui design'
      ],
      'date': '2019-11-10',
      'text': [
        'Projeto fictício, para estudo de UI/UX utilizando o Adobe Xd e o Adobe Photoshop.'
      ],
      'imageCover': 'project_56.png',
      'imageGallery': 'g_project_56.png',
      'fid': 56,
      'images': [
        '56_1.jpg',
        '56_2.jpg',
        '56_3.jpg',
        '56_4.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': 'https://www.behance.net/gallery/87976699/E-commerce-UIUX?',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'ER 2019',
      'name': 'er-2019',
      'url': 'er-2019',
      'color': '#63886d',
      'description': 'Identidade visual do evento ER - 2019',
      'tags': [
        'design',
        'ilustração',
        'logo'
      ],
      'date': '2018-08-01',
      'text': [
        'Trabalhei no desenvolvimento da identidade visual do evento ER - 2019 Evento internacional de banco de dados (Entidade-Relacionamento) que em 2019 acontecerá em Salvador, BA.'
      ],
      'imageCover': 'project_41.png',
      'imageGallery': 'g_project_41.png',
      'fid': 41,
      'images': [
        '41_1.png',
        '41_2.png',
        '41_3.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://www.inf.ufrgs.br/er2019/'
        ],
        'bhc': 'https://www.behance.net/gallery/71344363/ER-2019',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'The star wars project',
      'name': 'the-stars-wars-project',
      'url': 'the-stars-wars-project',
      'color': '#192139',
      'description': 'Aplicação React.js desenvolvida utilizando a Star Wars API.',
      'tags': [
        'design',
        'ilustração',
        'programming',
        'site',
        'ui design'
      ],
      'date': '2019-01-09',
      'text': [
        'Desenvolvi uma simples aplicação em ReactJs para calcular o número de paradas que uma nave espacial do Star Wars tem que fazer dado uma determinada distância. O back-end foi feito em NodeJs e os dados foram estraídos da <a href="https://swapi.co/" target="_blank">Star Wars API</a> (API pública).',
        'Além disso também desenhei as naves, sim, eu desenhei todas. Você pode conferir no Behance.'
      ],
      'imageCover': 'project_47.png',
      'imageGallery': 'g_project_47.png',
      'fid': 47,
      'images': [
        '47_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://the-stars-wars-api.firebaseapp.com/'
        ],
        'bhc': 'https://www.behance.net/gallery/77017047/Starships-Star-Wars',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/the-star-wars-api'
      }
    },
    {
      'title': 'Frequences of terms on Bible',
      'name': 'dataviz-frequence',
      'url': 'dataviz-frequence',
      'color': '#666699',
      'description': 'Visualização de dados',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2018-07-20',
      'text': [
        'Implementação de visualização em D3.js'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_44.png',
      'fid': 44,
      'images': [
        '44_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'http://bl.ocks.org/marialuisacp/086d4fa672854281b0b3d0de2326f28d'
      }
    },
    {
      'title': 'Template - Line chart with points',
      'name': 'dataviz-line-chart',
      'url': 'dataviz-line-chart',
      'color': '#ffa04c',
      'description': 'Visualização de dados',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2018-10-01',
      'text': [
        'Implementação de visualização em D3.js'
      ],
      'imageCover': 'project_42.png',
      'imageGallery': 'g_project_42.png',
      'fid': 42,
      'images': [
        '42_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://bl.ocks.org/marialuisacp/514bfcfe4e5e58b27598358f70cc5adf'
      }
    },
    {
      'title': 'UI E-commerce',
      'name': 'ui-ecommerce',
      'url': 'ui-ecommerce',
      'color': '#48484e',
      'description': 'Design e implementação de interfaces para e-commerce',
      'tags': [
        'ui design',
        'site',
        'programming'
      ],
      'date': '2018-06-01',
      'text': [
        'Realizei este projeto como teste técnico em um processo seletivo. No teste eu fiz o desenho e implementação das interfaces usando Angular 4. Para persistir os dados usei MongoDB e no back-end NodeJs.'
      ],
      'imageCover': 'project_37.png',
      'imageGallery': 'g_project_37.png',
      'fid': 37,
      'images': [
        '37_1.png',
        '37_2.png',
        '37_3.png',
        '37_4.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://uiecommerceminu.000webhostapp.com/'
        ],
        'bhc': 'https://www.behance.net/gallery/71348115/UI-e-commerce',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/uiecommerce'
      }
    },
    {
      'title': 'Relatórios de Visualização de dados',
      'name': 'relatorios-visualizacao-de-dados',
      'url': 'dataviz-reports',
      'color': '#0865af',
      'description': 'Desenvolvi visualizações com dados de relatórios de produto',
      'tags': [
        'dataviz',
        'programming',
        'ui design'
      ],
      'date': '2018-12-06',
      'text': [
        'De forma independente, criei alguns relatórios para a empresa que trabalho para apresentar juntamente a minha palestra sobre Visualização de dados. Por questões de privacidade dos dados, os relatórios completos não serão exibidos aqui.'
      ],
      'imageCover': 'project_49.png',
      'imageGallery': 'g_project_49.png',
      'fid': 49,
      'images': [
        '49_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'Cartilha aleitamento materno',
      'name': 'cartilha-aleitamento-materno',
      'url': 'breastfeeding-guide',
      'color': '#bd4ea7',
      'description': 'Trabalho de ilustração',
      'tags': [
        'ilustração',
        'design'
      ],
      'date': '2018-01-20',
      'text': [
        'Trabalhei em um projeto para o Departamento de Nutrição da UFVJM (Universidade Federal dos Vales do Jequitinhonha e Mucuri), onde meu objetivo foi desenvolver ilustrações para uma cartilha sobre aleitamento materno.',
        'Ao final do trabalho, a cartilha foi impressa e distribuída no Hospital Nossa Senhora da Saúde. Minha contribuição no projeto foi fazer as ilustrações e também a diagramação. Alguns dos desenhos estão abaixo.'
      ],
      'imageCover': 'project_33.png',
      'imageGallery': 'g_project_33.png',
      'fid': 33,
      'images': [
        '33_21.png',
        '33_22.png',
        '33_24.png',
        '33_23.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': 'https://www.behance.net/gallery/71347969/Cartilha-de-Aleitamento-Materno',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Grafos - Algoritmo de Dijkstra e desigualdade triangular',
      'name': 'dijkstra-algorithm',
      'url': 'dijkstra-algorithm',
      'color': '#313131',
      'description': 'Implementação de algoritmos para grafos:  Dijkstra e desigualdade triangular',
      'tags': [
        'programming'
      ],
      'date': '2018-04-15',
      'text': [
        'Este trabalho apresenta o projeto prático de Modelagem em Grafos da disciplina Processamento e Análise de Algoritmos do Programa de Pós Graduação em Ciência da Computação na Universidade Federal de Minas Gerais. Nele, realizo a implementação de uma solução para um problema computacional modelado em grafos por meio do Algoritmo de Dijkstra e do princípio da desigualdade triangular.',
        'O código foi desenvolvido em Java e se encontra público. A descrição e documentação do projeto podem ser encontrados no <a href="https://github.com/marialuisacp/Grafos/blob/master/documentacao.pdf" target="_blank">link</a>.'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_52.png',
      'fid': 52,
      'images': [
        '52_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://github.com/marialuisacp/Grafos/blob/master/documentacao.pdf'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/Grafos'
      }
    },
    {
      'title': 'Dashboards de sistema',
      'name': 'system-dashboards',
      'url': 'system-dashboards',
      'color': '#ff612e',
      'description': 'Design e front-end de sistema',
      'text': [
        'Neste projeto trabalhei com o design e a implementação das interfaces do sistema, criação e modelagem dos relatórios emitidos e dos dashboards.',
        'Por questões de privacidade, apenas alguns dashboards estão sendo mostrados e nome/logo do sistema também foram ocultados.'
      ],
      'date': '2017-05-07',
      'tags': [
        'sistema',
        'programming',
        'design',
        'ui design',
        'dataviz'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_27.png',
      'fid': 27,
      'images': [
        '27_1.png',
        '27_2.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'NLP - Natural Language Processing (Experiment Results in D3.js)',
      'name': 'dataviz-nlp-experiment',
      'url': 'dataviz-nlp-experiment',
      'color': '#333',
      'description': 'Visualização de dados',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2018-04-01',
      'text': [
        'Neste projeto desenvolvi uma visualização com dados do Word2Vec - Neural Word Embedding com os dados da Wikipédia brasileira.'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_43.png',
      'fid': 43,
      'images': [
        '43_1.png',
        '43_2.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/Wikipedia-Data-Force-Directed-Graph-'
      }
    },
    {
      'title': 'Visualização - Total Business Inventories and Sales Data',
      'name': 'total-business-inventories-and-sales-data',
      'url': 'total-business-inventories-and-sales-data',
      'color': '#5fa952',
      'description': 'Visualizaçṍes de séries temporais desenvolvida com D3Js v3, utilizando os dados do U.S. Census Bureau.',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2019-06-23',
      'text': [
        'Desenvolvimento de duas visualizações, gráfico de barras e gráfico de linhas para visualizar a série temporal com dados (Total Business Inventories and Sales Data). Neste projeto utilizei:',
        '<ul> <li>D3.js (v3);</li> <li>ECMAScript 6;</li> <li>ES6+ Js;</li> <li>Total Business Inventories and Sales Data (<a href="https://www.kaggle.com/census/total-business-inventories-and-sales-data" target="_blank">Disponível em Kaggle</a>);</li></ul>'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_54.png',
      'fid': 54,
      'images': [
        '54_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://marialuisacp.github.io/labviz---time-serie-Total-Business-Inventories-and-Sales-Data/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/labviz---time-serie-Total-Business-Inventories-and-Sales-Data'
      }
    },
    {
      'title': 'App Help Festival',
      'name': 'app-help-festival',
      'url': 'app-help-festival',
      'color': '#5f93bb',
      'description': 'Aplicativo para o Help Festival 2017',
      'text': [
        'Desenvolvi um aplicativo informativo para um festival cristão que acontece em Campo Mourão, o Help Festival.',
        'O objetivo do evento é levar palestrantes, workshops com temas referentes a missões em um final de semana, além de bandas e artistas.',
        'O aplicativo foi desenvolvido para dispositivos Android e iOS com o objetivo de trazer informações referentes ao evento como localização, programação, palestrantes, mapa, formulário de contato e avaliação do evento. O aplicativo se encontra disponível nas lojas.'
      ],
      'date': '2017-01-09',
      'tags': [
        'app',
        'programming',
        'design',
        'ui design'
      ],
      'imageCover': 'project_30.png',
      'imageGallery': 'g_project_30.png',
      'fid': 30,
      'images': [
        '30_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://www.helpfestival.com/'
        ],
        'bhc': '',
        'app_and': 'https://play.google.com/store/apps/details?id=com.ionicframework.helpfestival895626',
        'app_ios': 'https://itunes.apple.com/us/app/help-festival/id1286950329?l=pt&ls=1&mt=8',
        'comp': ''
      }
    },
    {
      'title': 'Site CGRIFES',
      'name': 'site-cgrifes',
      'url': 'site-cgrifes',
      'color': '#6aa9de',
      'description': 'Desenvolvimento de site',
      'text': [
        'Em contrato com o Departamento de Relações Internacionais da Universidade Federal de Minas Gerais, trabalhei no desenvolvimento do site para o CGRIFES - Colégio de Gestores de Relações Internacionais das IFES.',
        'O site foi desenvolvido em wordpress.'
      ],
      'date': '2017-27-09',
      'tags': [
        'site',
        'design',
        'ui design'
      ],
      'imageCover': 'project_10.png',
      'imageGallery': 'g_project_10.png',
      'fid': 10,
      'images': [
        '10_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Visualização do inchaço populacional no Brasil',
      'name': 'dataviz-br-population-cities',
      'url': 'dataviz-br-population-cities',
      'color': '#00958d',
      'description': 'Visualização de dados com informações da distribuição populacional no Brasil',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2019-03-01',
      'text': [
        'Neste projeto estou trabalhando com dados do IBGE e mostrei em um mapa a distribuição da população brasileira nas cidades.',
        'Um dos objetivos deste projeto foi praticar o uso da biblioteca D3js com o Google Maps.'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_51.png',
      'fid': 51,
      'images': [
        '51_1.png',
        '51_2.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://bl.ocks.org/marialuisacp/64fe2449243a8043946d4f5ce4906e6d'
      }
    },
    {
      'title': 'João Camilo Camisas',
      'name': 'joao-camilo-camisas',
      'url': 'joao-camilo-shirts',
      'color': '#486256',
      'description': 'Designer da marca',
      'text': [
        'Neste projeto trabalhei na construção da marca João Camilo.',
        'João Camilo é o nome da marca de moda masculina, focado em camisaria. A marca João Camilo se apresenta uma camisaria inovadora, com foco em um público diferenciado e antenado, que busca algo mais do que o funcional. Nosso comprometimento se inicia com nossos funcionários e finaliza com a satisfação dos nossos clientes. Para isso, proporcionamos uma experiência de compra diferenciada e divertida.',
        'A escolha do nome João Camilo, foi inspirada no cachorro (buldogue inglês) de um dos sócios, portanto a figura do cachorro é um elemento que remete a essência da marca.',
        'Embora utilize o cão como elemento simbólico, este não deve passar a ideia de cão doméstico, mas trazer um visual selvagem, bravo, o tradicional buldogue.'
      ],
      'date': '2017-10-01',
      'tags': [
        'design',
        'logo'
      ],
      'imageCover': 'project_03.png',
      'imageGallery': 'g_project_03.png',
      'fid': 3,
      'images': [
        '3_7.png',
        '3_9.png',
        '3_5.png',
        '3_6.png',
        '3_1.png',
        '3_2.png',
        '3_4.png',
        '3_10'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': 'https://www.behance.net/gallery/71348615/Joao-Camilo-Camisas',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Sites DRI/UFMG',
      'name': 'sites-dri-ufmg',
      'url': 'sites-dri-ufmg',
      'color': '#b70e0c',
      'description': 'Desenvolvimento de sites institucionais',
      'text': [
        'Trabalhei no desenvolvimento de três sites institucionais para o Departamento de Relações Internacionais da Universidade Federal de Minas Gerais.',
        'Os sites foram para os Centros de Estudos Latino Americaos, Centro de Estudos Europeus e para o Centro de Estudos Africanos. Todos foram desenvolvidos em wordpress seguindo as orientações dadas pelo Centro de Comunicação da Universidade.'
      ],
      'date': '2017-05-07',
      'tags': [
        'site',
        'design',
        'ui design'
      ],
      'imageCover': 'project_15.png',
      'imageGallery': 'g_project_15.png',
      'fid': 15,
      'images': [
        '15_1.png',
        '15_2.png',
        '15_3.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://www.ufmg.br/dri/cee/',
          'https://www.ufmg.br/dri/cea/',
          'https://www.ufmg.br/dri/cela/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Grupo Joyfully',
      'name': 'grupo-joyfully',
      'url': 'grupo-joyfully',
      'color': '#7c59c2',
      'description': 'Designer da marca',
      'text': [
        'Neste projeto trabalhei na construção da marca Joyfully.',
        'Joyfully é o nome do grupo de músicos que realiza apresentações em casamentos, eventos empresariais e cerimônias que precisam de uma música clássica. A identidade visual do grupo foi pensada para reunir elementos visuais que representam aquilo que o grupo expressa. O termo Joyfully no inglês expressa o sentimento de uma pessoa estando muito feliz. Assim a identidade visual do grupo foi pensada de modo a trazer alegria e criatividade ao grupo.',
        'A clave foi usada para representar a musicalidade do grupo substituindo a letra J do nome no logotipo. Além disso a palavra Joy foi destacada ‘Alegria’.',
        'As etapas da criação e o resultado final da foi a composição dos elementos estão representadas abaixo.'
      ],
      'date': '2017-05-07',
      'tags': [
        'design',
        'logo'
      ],
      'imageCover': 'project_04.png',
      'imageGallery': 'g_project_04.png',
      'fid': 4,
      'images': [
        '4_1.png',
        '4_3.png',
        '4_2.jpg',
        '4_4.png',
        '4_5.png',
        '4_6.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://grupojoyfully.com/'
        ],
        'bhc': 'https://www.behance.net/gallery/71344817/Joyfully',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': '1920 artes',
      'name': '1920-artes',
      'url': '1920-arts',
      'color': '#df1980',
      'description': 'Desenhos que trazem algumas reflexões',
      'text': [
        'Fiz alguns desenhos com alguma frase criativa.'
      ],
      'date': '2017-10-01',
      'tags': [
        'design',
        'ilustração'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_45.png',
      'fid': 45,
      'images': [
        '45_1.png',
        '45_2.png',
        '45_3.png',
        '45_4.png',
        '45_5.png',
        '45_6.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://1920artes.tumblr.com/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Marketing Professional',
      'name': 'markging-professional',
      'url': 'markging-professional',
      'color': '#476c96',
      'description': 'Designer de logotipo',
      'tags': [
        'design',
        'logo'
      ],
      'date': '2017-05-07',
      'text': [
        'Este projeto consiste no desenvolvimento de um logotipo para o projeto Marketing Professional.'
      ],
      'imageCover': 'project_05.png',
      'imageGallery': 'g_project_05.png',
      'fid': 5,
      'images': [
        '5_1.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'https://markgingprofessional.com'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Aposte Agora',
      'name': 'aposte-agora',
      'url': 'aposte-agora-app',
      'color': '#6ca137',
      'description': 'Aplicativo de apostas',
      'tags': [
        'sistema',
        'programming',
        'design',
        'app',
        'ui design'
      ],
      'date': '2017-05-07',
      'text': [
        'Este projeto consiste do desenvolvimeto de um aplicativo de apostas. Tanto o nome do projeto quanto a marca foram alteradas pelos donos do projeto. No projeto trabalhei com o desenho das telas do aplicativo.'
      ],
      'imageCover': 'project_21.png',
      'imageGallery': 'g_project_21.png',
      'fid': 21,
      'images': [
        '21_1.png',
        '21_2.png',
        '21_3.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'ChoferApp',
      'name': 'chofer-app',
      'url': 'chofer-app',
      'color': '#bc2037',
      'description': 'Desenvolvimento de telas para aplicativo',
      'text': [
        'ChoferApp é um aplicativo também conhecido como Uber Clone. Se trata de um aplicativo mobile com a implementação de um sistema de gerenciamento de transporte. Este app é um modelo que pode aplicar o mesmo modelo de negócios a outras demandas de transporte.',
        'O aplicativo se encontra disponível para dispositivos Android e iOS. Trabalhei na implementação das interfaces do aplicativo em ambas plataformas, conforme o design prototipado. Também auxiliei nas interfaces, dando sugestões de melhorias.'
      ],
      'date': '2017-05-07',
      'tags': [
        'app',
        'design',
        'ui design'
      ],
      'imageCover': 'project_28.png',
      'imageGallery': 'g_project_28.png',
      'fid': 28,
      'images': [
        '28_1.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'http://codificar.com.br/produtos/aplicativo-uber-clone-script/'
        ],
        'bhc': '',
        'app_and': 'https://play.google.com/store/apps/details?id=com.br.chofer',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Sites pós graduação',
      'name': 'sites-pos-graduacao-ufmg',
      'url': 'sites-postgraduate-ufmg',
      'color': '#d03f4a',
      'description': 'Desenvolvimento de site',
      'tags': [
        'site'
      ],
      'date': '2017-05-07',
      'text': [
        'Fui contratada como profissional autônoma pelo Cedecom (Centro de comunicação da UFMG) para o desenvolvimento dos novos sites para os programas de Pós graduação da Universidade. Minha atuação foi configuração de alguns sites no servidores e customização dos templates. Os sites foram feitos usando wordpress.'
      ],
      'imageCover': 'project_29.png',
      'imageGallery': 'g_project_29.png',
      'fid': 29,
      'images': [
        '29_1.jpg',
        '29_2.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://ppgcc.dcc.ufmg.br/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Population Time Series Data',
      'name': 'population-time-series-data',
      'url': 'population-time-series-data',
      'color': '#b38ed3',
      'description': 'Visualização desenvolvida com D3Js v4.',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2019-06-23',
      'text': [
        'Desenvolvimento de visualização simples (Gráfico de área) com D3Js, utilizando:',
        '<ul> <li>D3.js (v4);</li> <li>ECMAScript 6;</li> <li>ES6+ Js;</li> <li>Data Population Time Series. (<a href="https://www.kaggle.com/census/population-time-series-data" target="_blank">Disponível em Kaggle</a>);</li></ul>'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_53.png',
      'fid': 53,
      'images': [
        '53_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'https://marialuisacp.github.io/labviz---Population-Time-Series-Data/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/labviz---Population-Time-Series-Data'
      }
    },
    {
      'title': 'DocDoc',
      'name': 'docdoc',
      'url': 'docdoc-app',
      'color': '#666699',
      'description': 'Design e desenvolvimento de aplicativo e sistema',
      'text': [
        'DocDoc é uma rede social feita para médicos. O objetivo principal é fornecer uma rede de contato entre eles, além da divulgação de vagas de emprego, cursos, realização de exames, por parte das instituições de saúde.',
        'Fiz este trabalho pela Codificar, e nele atuei no design do aplicativo, além de desenhar o ícone do app.'
      ],
      'date': '2017-05-07',
      'tags': [
        'app',
        'sistema',
        'programming',
        'design',
        'logo',
        'ui design'
      ],
      'imageCover': 'project_07.png',
      'imageGallery': 'g_project_07.png',
      'fid': 7,
      'images': [
        '7_1.png',
        '7_3.png',
        '7_4.png',
        '7_5.png',
        '7_6.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Word2Vec: Analisando livros do Gutemberg',
      'name': 'word2vec-livros-do-gutemberg',
      'url': 'word2vec-gutemberg-books',
      'description': 'Processamento de linguagem natural com os dados dos livros',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2017-09-10',
      'text': [
        'Este trabalho teve por objetivo a implementação de modelos de linguagem para uma biblioteca de livros, a fim de aplicar o método Word2Vec. A base de livro foi tirada do site Gutemberg (https://www.gutenberg.org/). A lista dos livros pode ser encontrada no <a href="http://homepages.dcc.ufmg.br/~maria.luisa/files/nlp/books.txt" target="_blank">link.',
        'Word2vec é uma rede neural que processa texto. Em resumo, pode-se dizer que o objetivo deste método é aprender termos similares com base no contexto. Sua entrada é um corpo de texto e a saída é um conjunto de vetores: vetores de características das palavras. Word2vec não é uma rede neural profunda, ele transforma o texto em um formato numérico que as redes profundas podem entender. Neste projeto foi utilizado o método tomando-se como base de dados, os livros do Gutemberg.',
        'Foi realizado um pré processamento colocando as palavras em minúsculo e separando-as dos sinais de pontuação (vírgulas, acentos, pontos finais, exclamação, etc). Não foi feito nenhum método de Stemming portanto as variações das palavras (plurais, conjugação verbal, entre outros) não foram unificadas. Foi feita a remoção das stop words nas análises. A lista das stop words pode ser acessada no <a href="http://homepages.dcc.ufmg.br/~maria.luisa/files/nlp/stop_words.txt" target="_blank">link</a>.',
        'Foram utilizadas duas implementações do Word2Vec nas análises contidas neste trabalho. A primeira é um <a href="https://github.com/tmikolov/word2vec" target="blank">projeto aberto contido no Github</a> desenvolvido pela Google em linguagem C (<a href="https://code.google.com/archive/p/word2vec/" target="_blank">Ver documentação do código</a>).',
        'A segunda implementação que também foi bem útil foi à pertencente a <a href="https://deeplearning4j.org/word2vec.html" target="_blank">biblioteca Deeplearning4j</a>. Esta segunda foi usada para observar e analisar os livros individualmente e gerar visualizações que facilitasse as análises. As visualizações foram feitas utilizando a biblioteca D3Js.',
        'A forma como este trabalho foi desenvolvido é apenas uma opção de como codificar palavras em representações numéricas. A ideia explorada pelo word2vec é a de reconhecer uma palavra pela companhia que ela mantém, saber o que uma palavra significa pelo contexto em que ela está inserida. Assim cada palavra é representada por um vetor. A distância entre estes vetores revela o quanto é forte a conexão entre as palavras no contexto aprendido. Dados os vetores das palavras é possível construirmos modelos de linguagem para cada livro, afinal cada livro possui uma linguagem própria.',
        'A fim de descobrir modelos de linguagem dos livros e conseguir realizar análises sobre eles, o método foi utilizado em cada livro observando-se as palavras mais frequentes em cada um. A visualização  abaixo, mostra por meio de um grafo um exemplo de relações entre as palavras encontradas em um dos livros.',
        '<img src="../images/projects/50_1.png"/>',
        'Palavras e relações no livro: Autobiography_of_Benjamin_Franklin',
        'Os nós representam as palavras e as arestas representam as palavras',
        'O tamanho da fonte representa a frequência, onde pode-se ver dois tamanhos de fonte nas palavras:',
        '<ul><li>grande: estão entre os 20 termos mais frequentes;</li> <li>pequena: apenas palavras;</li></ul>',
        'A fim de comparar os modelos de linguagem nos livros, foi feito um experimento com as palavras mais frequentes nos livros. Foram usadas as 20 palavras mais frequentes em cada livro, removendo as stop words. Como a lista das stop words não estava muito grande e específica, algumas palavras como advérbios acabaram entrando na lista e isto foi inclusive um ponto positivo. Ao final, algumas stop words foram adicionadas para balanceara distribuição entre especificidades dos livros x palavras comuns nos livros (stop words). Assim a amostra contém tanto palavras importantes no contexto do livro, como também palavras genéricas que são encontradas em todos os livros. Vale ressaltar que é possível que talvez a análise não seja tão justa, tendo-se em vista que o tamanho dos livros varia muito. ',
        'Alguns testes foram realizados, a fim de obter o melhor tamanho para a amostra. A amostragem final continha 75 palavras.',
        'Esta amostragem foi usada como base para geração de uma matriz de cada livro, a fim de comparar a distância entre os livros. A decisão de utilizar palavras frequentes em cada livro foi devido ao fato das diferenças entre os temas do livros serem evidentes. Assim coletando as palavras mais frequentes, é possível assumir que estaria contida na amostra uma parte importante de cada livro. Devo ressaltar que as palavras mais frequentes em um determinado livro possivelmente (e provavelmente) estarão contidas em outro livro então potencialmente haverá uma relação entre as palavras (seja grande ou pequena).',
        'Na matriz quadrada de distância, as dimensões da matriz representam a distância entre pares de palavras. As palavras, são as que estão contidas na amostragem e serão comparadas uma a uma gerando a matriz de distância (nxn).',
        'Baseando-se nos resultados encontrados, foi feita uma análise de alguns dos autores contidos na base de livros utilizada.',
        '<span class="featured-term">Agatha Christie</span>',
        'O livros de Agatha Christie: The Secret Adversary e The Mysterious Affair at Styles.',
        'A escrita de Agatha nos livros citados acima mostra uma variação no estilo de escrita do livro. Em The Mysterious Affair at Styles a autora apresentou um texto mais “melancólico”. Observe como os termos estão fortemente interligados. Já em The Secret Adversary as palavras parecem ter recebido uma independência maior. ',
        'A distância observada entre os livros foi de: 1.1394',
        '<p class="center"><img src="../images/projects/50_2.jpg"/></p>',
        '<p class="center">Grafos de modelos observados em <span class="italic">The Secret Adversary </span></p>',
        '<p class="center"><img src="../images/projects/50_3.jpg"/></p>',
        '<p class="center">Grafos de modelos observados em <span class="italic">The Mysterious Affair at Styles </span></p>',
        '<span class="featured-term">Lewis Carroll</span>',
        'Os livros de Lewis Carroll contidos na base foram Alice"s Adventures in Wonderland e Through the Looking-Glass. A temática dos livros talvez tenha contribuído para a distância observada nos mesmos. Os livros não possuem muita relação aparente. Talvez em cada uma de suas autorias procure apresentar novas temáticas tanto referente ao conteúdo como a forma de escrever. O enredo de Alice"s Adventures in Wonderland parece ser bem peculiar, apresentando uma relação considerável das palavras contidas no texto (umas com as outras). Este fenômeno também ocorre em  Through the Looking-Glass embora os textos não se pareçam. A distância observada entre os livros foi de: 5.67594',
        '<p class="center"><img src="../images/projects/50_4.jpg"/></p>',
        '<p class="center">Grafos de modelos observados em <span class="italic">Alice"s Adventures in Wonderland</span></p>',
        '<p class="center"><img src="../images/projects/50_5.jpg"/></p>',
        '<p class="center">Grafos de modelos observados em <span class="italic"> Through the Looking-Glass </span></p>',
        '<span class="featured-term">William Shakespeare</span>',
        'Dos livros de Shakespeare analisados, (são eles The Complete Works of William, The Tragedy of Romeo and Juliet e The Complete Works of William Shakespeare) pudemos analisar uma relação aparente entre eles embora Shakespeare aparenta mostrar uma diferença significativa na escrita (talvez devido aos temas diferentes de seus livros). O livros de Shakspare se assemelham também a outras literaturas. Não foi aparente a semelhança de seus livros em relação aos demais comparados.'
      ],
      'imageCover': 'project_50.png',
      'imageGallery': 'g_project_50.png',
      'fid': 50,
      'images': [],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': ''
      }
    },
    {
      'title': 'Kangaroo Kebab',
      'name': 'kangaro-kebab',
      'url': 'kangaro-kebab-website',
      'color': '#e23c18',
      'description': 'Desenvolvimento de site',
      'tags': [
        'site'
      ],
      'date': '2017-05-07',
      'text': [
        'Kangaroo Kebabs é uma rede de fast food presente em vários países. Trabalhei como freelancer para a Agência Megaphone no front-end do site para Kangaroo Kebabs. No projeto desenvolvi as telas responsivas conforme o protótipo que me foram passados. '
      ],
      'imageCover': 'project_25.png',
      'imageGallery': 'g_project_25.png',
      'fid': 25,
      'images': [
        '25_3.jpg',
        '25_2.jpg',
        '25_1.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [
          'http://kangarookebabs.com.br/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Lagoa Flats',
      'name': 'lagoa-flats',
      'url': 'site-lagoa-flats',
      'color': '#013334',
      'description': 'Design gráfico',
      'tags': [
        'design',
        'logo'
      ],
      'date': '2015-01-22',
      'text': [
        'Trabalhei no desenvolvimento do logo tipo da Lagoa Flats, um sreviço de aluguel de flats.'
      ],
      'imageCover': 'project_12.png',
      'imageGallery': 'g_project_12.png',
      'fid': 12,
      'images': [
        '12_1.jpg',
        '12_2.png',
        '12_3.png',
        '12_4.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Appjuridico',
      'name': 'appjuridico',
      'url': 'appjuridico-app',
      'color': '#32737e',
      'description': 'Design e desenvolvimento de aplicativo',
      'text': [
        'Neste aplicativo trabalhei com o desenho das interfaces e o desenvolvimento do aplicativo AppJurídico. Este aplicativo foi feito para advogados que desejam encontrar correspondentes jurídicos.',
        'Foi feito em 2015 pela Codificar e eu estive presente no time de desenvolvimento. No projeto trabalhei fazendo reuniões com o cliente, a fim de entender a demanada e modelar o aplicativo. Fiz o protótipo das telas e desenvolvi o aplicativo. Os feedbacks do cliente foram positivos.',
        'O aplicativo foi feito com Ionic Framework.'
      ],
      'date': '2015-10-01',
      'tags': [
        'app',
        'programming',
        'design',
        'ui design'
      ],
      'imageCover': 'project_11.png',
      'imageGallery': 'g_project_11.png',
      'fid': 11,
      'images': [
        '11_1.png',
        '11_2.jpg'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'https://appjuridico.com.br'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Construgab',
      'name': 'construgab',
      'url': 'construgab-design',
      'color': '#be9824',
      'description': 'Design gráfico',
      'tags': [
        'design',
        'logo'
      ],
      'date': '2017-05-07',
      'text': [
        'Este projeto consistiu na criação da marca Construgab, uma construtora em Belo Horizonte.',
        'Trabalhei na criação da marca e de materiais de divulgação, como material impresso e website. Infelizmente a empresa encerrou seus serviços atualmente.'
      ],
      'imageCover': 'project_08.png',
      'imageGallery': 'g_project_08.png',
      'fid': 8,
      'images': [
        '8_1.png',
        '8_2.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'CelebraFotos',
      'name': 'celebra-fotos',
      'url': 'celebra-fotos-app',
      'color': '#1878a6',
      'description': 'Desenvolvimento de aplicativo para Smart TV',
      'text': [
        'Este foi um aplicativo feito para Smart TV. É um aplicativo similar a instagram, porém para uma TV.',
        'Funciona como uma cabine fotográfica. Os usuários tiram fotos e editam na TV com touch screen.',
        'Trabalhei no design e desenvolvimento das interfaces. A ferramenta utilizada foi WinJs, um Framework Javascript nativo para criação de aplicativos para windows.'
      ],
      'date': '2015-08-10',
      'tags': [
        'sistema',
        'programming',
        'design',
        'ui design'
      ],
      'imageCover': 'project_17.png',
      'imageGallery': 'g_project_17.png',
      'fid': 17,
      'images': [
        '17_1.png',
        '17_2.png',
        '17_3.png',
        '17_4.png',
        '17_5.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'https://codificar.com.br/portfolios/celebra-fotos/'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'Controle de fluxo de caixa',
      'name': 'controle-de-fluxo-de-caixa',
      'url': 'cash-flow-control',
      'color': '#336699',
      'description': 'Sistema de contro de fluxo de caixa',
      'tags': [
        'design',
        'sistema',
        'programming',
        'dataviz',
        'ui design'
      ],
      'date': '2017-05-07',
      'text': [
        'Este foi um simples projeto desenvolvido por mim em 2015 como uma tarefa para um processo seletivo de uma empresa. Se trata de um sisteminha de controle de fluxo de caixa, onde é possível inserir as despesas e os ganhos em um determinado período e o sistema deve retornar o saldo e um gráfico com análises.',
        'O trabalho foi aprovado com êxito. Para desenvolvê-lo utilizei AngularJs e o gráfico foi feito em D3Js.'
      ],
      'imageCover': 'project_16.png',
      'imageGallery': 'g_project_16.png',
      'fid': 16,
      'images': [
        '16_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': '',
        'code': 'https://github.com/marialuisacp/Cash-Flow'
      }
    },
    {
      'title': 'UFMG na rede',
      'name': 'ufmg-na-rede',
      'url': 'ufmg-na-rede',
      'color': '#ca171d',
      'description': 'Desenvolvimento de site',
      'tags': [
        'site',
        'ui design'
      ],
      'date': '2013-09-08',
      'text': [
        'Neste projeto eu trabalhei no desenvolvimento de um portal de notícias da Universidade. Na verdade ele é como um blog com posts diários, que reune links de várias notícias relacionadas a Universidade. A UFMG é uma universidade enorme, são muitos institutos, departamentos, faculdades, e áreas específicas. Assim o objetivo do "UFMG na rede" é reunir em um só local, os links das principais informações e notícias diárias.',
        'Eu trabalhei com o desenvolvimento do site em wordpress, e também no desenvolvimento de plugins wordpress, e tipos específicos de post.'
      ],
      'imageCover': 'project_26.png',
      'imageGallery': 'g_project_26.png',
      'fid': 26,
      'images': [
        '26_1.png'
      ],
      'company': 'Cedecom - Centro de Comunicação, Universidade Federal de Minas Gerais',
      'links': {
        'site': [
          'https://www.ufmg.br/narede'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'https://ufmg.br/comunicacao/sobre-o-cedecom'
      }
    },
    {
      'title': 'UFMG meu lugar',
      'name': 'site-ufmg-meu-lugar',
      'url': 'site-ufmg-meu-lugar',
      'color': '#ff3859',
      'description': 'Desenvolvimento de site',
      'tags': [
        'site'
      ],
      'date': '2017-05-07',
      'text': [
        'Trabalhei na implementação do site UFMG meu lugar, feito em wordpress pelo Centro de Comunicação da Universidade Federal de Minas Gerais.'
      ],
      'imageCover': 'project_20.png',
      'imageGallery': 'g_project_20.png',
      'fid': 20,
      'images': [
        '20_1.png'
      ],
      'company': 'Cedecom - Centro de Comunicação, Universidade Federal de Minas Gerais',
      'links': {
        'site': [
          'https://www.ufmg.br/meulugar'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'https://ufmg.br/comunicacao/sobre-o-cedecom'
      }
    },
    {
      'title': 'Website institucional ijunior',
      'name': 'site-ijunior',
      'url': 'site-ijunior',
      'color': '#00548e',
      'description': 'Desenvolvimento de site',
      'tags': [
        'site',
        'ui design'
      ],
      'date': '2017-05-07',
      'text': [
        'Como membro da UFMG Informática Jr. em 2013 trabalhei no desenvolvimento do site institucional da empresa.'
      ],
      'imageCover': 'project_23.png',
      'imageGallery': 'g_project_23.png',
      'fid': 23,
      'images': [
        '23_1.png'
      ],
      'company': 'UFMG Informática Jr.',
      'links': {
        'site': [
          'https://www.ijunior.com.br'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.ijunior.com.br/'
      }
    },
    {
      'title': 'Scaffolder',
      'name': 'scaffolder',
      'url': 'scaffolder',
      'color': '#009c3d',
      'description': 'Design e front-end do projeto',
      'text': [
        'O que é o Scaffolder? Scaffolder é um gerador de código fonte para sistemas web, desenvolvido pela Codificar. A ideia principal deste projeto é um software que desenvolva funções que são comumente utilizadas em sistemas web de forma automatizada.',
        'A partir de uma modelagem de banco de dados, o Scaffolder gera os códigos referentes a funções CRUD (Create, Read, Update e Delete) de cada tabela do banco, além das telas de cadastro, edição e listagem.  back-end foi feito em Laravel PHP e o front-end em AngularJs.',
        'No projeto trabalhei com a modelagem e o desenho dos modelos de interface que o gerador iria criar. Também atuei no desenvolvimento dos diversos tipos de componentes de UI, como datatables, listas hierarquicas, campos avançados de formulário como autocomplete e upload de fotos de arquivos.'
      ],
      'date': '2017-05-07',
      'tags': [
        'sistema',
        'programming',
        'design',
        'logo',
        'ui design'
      ],
      'imageCover': 'project_02.png',
      'imageGallery': 'g_project_02.png',
      'fid': 2,
      'images': [
        '2_1.png',
        '2_2.png',
        '2_3.png'
      ],
      'company': 'Codificar Sistemas Tecnológicos',
      'links': {
        'site': [
          'https://github.com/codificar/scaffolder-theme-material'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.codificar.com.br/'
      }
    },
    {
      'title': 'FomeFomeApp',
      'name': 'fomefome-app',
      'url': 'fomefome-app',
      'color': '#c41459',
      'description': 'Desenvolvimento de ícone para aplicativo e site',
      'tags': [
        'design',
        'logo',
        'site'
      ],
      'date': '2015-11-17',
      'text': [
        'Trabalhei como freelancer para a codificar desenvolvimento uma landing page para o aplicativo FomeFomeApp, um aplicativo de localização de Delivery. Também desenvolvi um ícone para o aplicativo com os símbolos de localização e um prato de comida.'
      ],
      'imageCover': 'project_06.png',
      'imageGallery': 'g_project_06.png',
      'fid': 6,
      'images': [
        '6_4.png',
        '6_3.png',
        '6_2.png',
        '6_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'ACR Elevadores',
      'name': 'site-acr-elevadores',
      'url': 'site-acr-lifts',
      'color': '#4b8842',
      'description': 'Desenvolvimento de site',
      'tags': [
        'site',
        'design',
        'ui design'
      ],
      'date': '2013-06-06',
      'text': [
        'Trabalhei no desenvolvimento do site para ACR Elevadores.'
      ],
      'imageCover': 'project_18.png',
      'imageGallery': 'g_project_18.png',
      'fid': 18,
      'images': [
        '18_1.png'
      ],
      'company': 'UFMG Informática Jr.',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.ijunior.com.br/'
      }
    },
    {
      'title': 'Aplicativo Android - Cronoanálise',
      'name': 'cronoanalise-app',
      'url': 'cronoanalise-app',
      'color': '#cc7244',
      'description': 'Desenvolvimento de aplicativo android',
      'tags': [
        'app',
        'programming',
        'design',
        'ui design'
      ],
      'date': '2013-01-01',
      'text': [
        'Neste projeto trabalhei no desenvolvimento de um aplicativo android para uma empresa multi-nacional. Por questões de privacidade nenhuma imagem do aplicativo pode ser divulgada.',
        'A principal funcionalidade do aplicativo era o controle de tempo de atividades realizadas. O app foi desenvolvido para operar em tablets com sistema android.'
      ],
      'imageCover': 'project_24.png',
      'imageGallery': 'g_project_24.png',
      'fid': 24,
      'images': [],
      'company': 'UFMG Informática Jr.',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.ijunior.com.br/'
      }
    },
    {
      'title': 'LASA UFMG',
      'name': 'site-lasa-ufmg',
      'url': 'site-lasa-ufmg',
      'color': '#172848',
      'description': 'Desenvolvimento de site',
      'text': [
        'Trabalhei no desenvolvimento do site para LASA UFMG.'
      ],
      'date': '2013-10-05',
      'tags': [
        'design',
        'site',
        'ui design'
      ],
      'imageCover': 'project_19.png',
      'imageGallery': 'g_project_19.png',
      'fid': 19,
      'images': [
        '19_1.png'
      ],
      'company': 'UFMG Informática Jr.',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.ijunior.com.br/'
      }
    },
    {
      'title': 'Célula Bem aventurados',
      'name': 'bem-aventurados',
      'url': 'blessed-design',
      'color': '#2f5f8e',
      'description': 'Design para modelos de camisa',
      'tags': [
        'design',
        'logo'
      ],
      'date': '2013-07-10',
      'text': [
        'Desenvolvi um modelo de camisas para um grupo de células. Tem modelos tanto femininos, quanto masculinos.'
      ],
      'imageCover': 'project_22.png',
      'imageGallery': 'g_project_22.png',
      'fid': 22,
      'images': [
        '22_1.png',
        '22_2.png',
        '22_5.png',
        '22_6.png',
        '22_7.jpg',
        '22_8.jpg'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Visualização de popularidade musical',
      'name': 'dataviz-musical',
      'url': 'dataviz-musical',
      'color': '#756bb1',
      'description': 'Desenvolvimento de visualização de informação',
      'tags': [
        'dataviz',
        'programming'
      ],
      'date': '2014-08-01',
      'text': [
        'Desenvolvi este trabalho como um trabalho prático de Visualização de Dados. O objetivo do projeto é a criação de uma visualização a partir dos dados do site Prog Archives. O site é uma comunidade que reúne álbuns e artistas do gênero musical Rock Progressivo, além de notas e resenhas contribuídas por usuários. O objetivo da visualização foi apresentar a popularidade de um estilo musical ao longo do tempo. As cores são meramente ilustrativas.'
      ],
      'imageCover': '',
      'imageGallery': 'g_project_38.png',
      'fid': 38,
      'images': [
        '38_1.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Movie Mídia Site',
      'name': 'site-movie-midia',
      'url': 'site-movie-media',
      'color': '#d20000',
      'description': 'Desenvolvimento de site',
      'tags': [
        'site'
      ],
      'date': '2014-01-20',
      'text': [
        'Fiz um job como freelancer para a Agência Megaphone para o desenvolvimento do site Movie Mídia. Trabalhei apenas na implementação das interfaces.'
      ],
      'imageCover': 'project_13.png',
      'imageGallery': 'g_project_13.png',
      'fid': 13,
      'images': [
        '13_1.png',
        '13_2.png',
        '13_3.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    },
    {
      'title': 'Banners divulgação',
      'name': 'banners-ijunior',
      'url': 'banners-ijunior',
      'color': '#2990bf',
      'description': 'Publicidade',
      'text': [
        'Desenvolvi banners de divulgação de eventos para a UFMG Informática Jr.'
      ],
      'date': '2017-05-07',
      'tags': [
        'design'
      ],
      'imageCover': 'project_09.png',
      'imageGallery': 'g_project_09.png',
      'fid': 9,
      'images': [
        '9_1.png',
        '9_2.png'
      ],
      'company': 'UFMG Informática Jr.',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'http://www.ijunior.com.br/'
      }
    },
    {
      'title': 'Centro de Estudos indianos - Site',
      'name': 'site-cei',
      'url': 'site-cei',
      'color': '#6fb453',
      'description': 'Desenvolvimento de site',
      'tags': [
        'site'
      ],
      'date': '2015-01-20',
      'text': [
        'Trabalhei no desenvolvimento do site do Centro de Estudos Indianos para o Cedecom UFMG. O site foi feito em 2015 usando wordpress, quando trabalhei no Cedecom.'
      ],
      'imageCover': 'project_31.png',
      'imageGallery': 'g_project_31.png',
      'fid': 31,
      'images': [
        '31_1.png'
      ],
      'company': 'Cedecom - Centro de Comunicação, Universidade Federal de Minas Gerais',
      'links': {
        'site': [
          'https://www.ufmg.br/dri/cei'
        ],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': 'https://ufmg.br/comunicacao/sobre-o-cedecom'
      }
    },
    {
      'title': 'Luana Sistema',
      'name': 'sytem-luana',
      'url': 'sytem-luana',
      'color': '#3f5e2f',
      'description': 'Design e desenvolvimento de sistema',
      'tags': [
        'sistema',
        'programming',
        'design',
        'logo',
        'ui design'
      ],
      'date': '2015-06-20',
      'text': [
        'Este foi um trabalho feito em uma disciplina da faculdade, quando o trabalho proposto era desenvolver um sistema de cálculo variável.',
        'Minha participação foi o desenvolvimento do front-end do projeto e a criação do logotipo do sistema. Foi solicitado pelos integrantes que a marca tivesse um "chuchu" como símbolo principal.'
      ],
      'imageCover': 'project_32.png',
      'imageGallery': 'g_project_32.png',
      'fid': 32,
      'images': [
        '32_1.png',
        '32_2.png',
        '32_3.png',
        '32_4.png',
        '32_5.png'
      ],
      'company': 'Freelancer',
      'links': {
        'site': [],
        'bhc': '',
        'app_and': '',
        'app_ios': '',
        'comp': ''
      }
    }
  ],
};