import React, { Fragment, useEffect, useState } from 'react';
import { projects as allProjects } from '../../i18n/content/projects/en';
import {
  ContentProject, Project,
  ProjectLink, CoverProject, TitleProject, DescriptionProject,
} from './ProjectsList.styles';

const getIndexProject = (project) => {
  const idProject = project.url;
  const id = allProjects && allProjects.list && allProjects.list.findIndex((project) => project.url === idProject);
  return id;
};

const ProjectsList = ({ t, projects }) => {

  return projects && projects.map((item, index) => (
    <Project key={index}>
      <ProjectLink to={{
        pathname: `/project/${t(`projects.list.${getIndexProject(item)}.url`)}`,
      }}>
        <CoverProject url={t(`projects.list.${getIndexProject(item)}.imageGallery`)}></CoverProject>
        <ContentProject>
          <DescriptionProject>{t(`projects.list.${getIndexProject(item)}.description`)}</DescriptionProject>
        </ContentProject>
        <TitleProject color={item.color}>{t(`projects.list.${getIndexProject(item)}.title`)}</TitleProject>
      </ProjectLink>
    </Project>
  ));
};

export default ProjectsList;
